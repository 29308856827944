<template>
  <div class="apparea deal-tab">
    <header class="header-area">
        <div class="header">
            <div class="container">
              <figure class="logo bonauf-app-logo" @click="gotoHome">
                    <img :src="`${imageDomain}/assets/img/app/logo2.png`" alt="bonauf-app-logo" title="" />
                </figure>
                <button class="btn-close pr-2"  @click="gotoDealFilter">
                  <i class="fa-solid fa-chevron-right ml-5"></i>
                </button>
                <div class="header-text">
                  <h3>{{hotelDest}}</h3>
                  <span>{{hotelSubTitle}}</span>
                </div>
            </div>
        </div>
        <div class="header-tab">
            <div class="container d-flex">
                <button class="btn">{{type}}</button>
                <button class="btn">{{baggage}}</button>
                <!-- <button v-if="true" class="btn">כולל העברות</button> -->
                <div class="share-button" @click="() => showModal(activeDeal)" v-if="testMode">
                  <img :src="`${imageDomain}/assets/img/share-icon-white.png`" alt="share-icon" class="share-icon" width="23" height="20"/>
                </div>
            </div>
        </div>
    </header>
    <main class="body_warrper">
      <!-- banner area start -->
      <div v-if="hotelImage !== ''" class="banner-area">
        <img :src="hotelImage" :alt="hotelTitle" title="" />
      </div>
      <div class="tabarea">
        <div class="tab-heading">
            <div class="container d-flex">
              <button :class="['btn m-auto', {'active': isActive(index)}]" v-for="(tab, index) in tabs" :key="index" @click="activeTab(index)"><span>{{tab.title}}</span></button>
            </div>
        </div>
        <div class="tab-body">
          <div class="tab">
          <iframe :src="linkTab" />
          </div>
        </div>
      </div>
      <!-- price area start -->
      <div class="price-box">
          <div class="container">
              <div class="price-body">
                  <div class="price">
                      <p>החל מ-</p>
                      <h3>{{price}}</h3>
                      <p>לאדם</p>
                  </div>
                  <div class="price-text" @click="gotoProduct">
                      הזמן עכשיו
                  </div>
              </div>
          </div>
      </div>
      <!-- price area stop -->
      <b-modal id="share-this-modal" v-model="openShareModal">
        <template #modal-header>
          <h4> {{ $t("share-this.share-modal-title") }} </h4>
        </template>
        <template #default>
          <ShareThis :shareContent="shareContent"/>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button variant="danger" @click="cancel()">
            {{$t('booking.close')}}
          </b-button>
        </template>
      </b-modal>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

import {
  getId,
  getShareLink,
  getCategoryCode,
  getLinkImageDeal,
  getTitleDeal,
  getSubtitleDeal,
  getPriceDeal,
  getIsTransfer,
  getBaggage,
  getType,
  getTabs,
  getTitleTab,
  getLinkTab,
  getLink,
  getDestCode,
} from '@/utils/bonauf/controllerCommon';

const { VUE_APP_BONAUF_DOMAIN_MOBILE, VUE_APP_STAGING_DOMAIN } = process.env;

export default {
  name: 'DealTab',
  components: {
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  mixins: [imageUrlMixin],
  data() {
    return {
      activeTabIndex: 0,
      openShareModal: false,
      shareContent: null,
    };
  },
  computed: {
    ...mapGetters({
      testMode: 'GET_TEST_MODE',
      filteredDeals: 'GET_FILTERED_APP_DEALS',
      activeDeal: 'GET_SPECIFIC_APP_DEAL',
      getDestName: 'GET_DEST_NAME',
      getDestinationName: 'GET_DEST_NAME',
      getCategoryName: 'GET_CATEGORY_NAME',
    }),
    hotelImage() {
      return getLinkImageDeal(this.activeDeal);
    },
    hotelSubTitle() {
      return getSubtitleDeal(this.activeDeal);
    },
    hotelTitle() {
      return getTitleDeal(this.activeDeal);
    },
    hotelDest() {
      return this.getDestName(getDestCode(this.activeDeal));
    },
    isTransfer() {
      return getIsTransfer(this.activeDeal);
    },
    baggage() {
      return getBaggage(this.activeDeal);
    },
    type() {
      return getType(this.activeDeal);
    },
    price() {
      return getPriceDeal(this.activeDeal);
    },
    tabs() {
      return getTabs(this.activeDeal);
    },
    titleTab() {
      return getTitleTab(this.activeTabIndex, this.activeDeal);
    },
    linkTab() {
      return getLinkTab(this.activeTabIndex, this.activeDeal);
    },
  },
  async created() {
    await this.fetchAssetsData();
  },
  methods: {
    gotoHome() {
      this.$router.push('/app?channel=App');
    },
    async fetchAssetsData() {
      const { query } = this.$route,
        pickId = query.id;
      await this.$store.dispatch('FETCH_APP_DEAL_PACKAGES');
      // let pickDeal = this.filteredDeals.find((deal) => deal.id === pickId);
      // if (pickDeal === undefined) {
      //   pickDeal = await this.$store.dispatch('FETCH_APP_DEAL_SPECIFIC', { id: pickId });
      // }
      await this.$store.dispatch('FETCH_APP_DEAL_SPECIFIC', { id: pickId });
    },
    isActive(pIdx) {
      return this.activeTabIndex === pIdx;
    },
    activeTab(pIdx) {
      this.activeTabIndex = pIdx;
      this.path = this.active;
    },
    gotoProduct() {
      const { query } = this.$route,
        pickId = query.id;
      window.sessionStorage.setItem('dealId', pickId);
      this.$router.push({ path: getLink(this.activeDeal) });
    },
    gotoDealFilter() {
      this.$router.push({ path: '/deal-filter/app?channel=App' });
    },
    showModal(pDeal) {
      // console.log(pDeal);
      let link = getShareLink(pDeal);
      link = link.replace(VUE_APP_BONAUF_DOMAIN_MOBILE, '');
      link = link.replace(VUE_APP_STAGING_DOMAIN, '');
      this.shareContent = {
        id: getId(pDeal),
        link: encodeURI(`${link}&text=מצאתי דיל בבוא נעוף שיכול לעניין אותך, בוא נעוף ביחד`),
        category: this.getCategoryName(getCategoryCode(pDeal)),
        dest: this.getDestinationName(getDestCode(pDeal)),
        hotel: getTitleDeal(pDeal),
        image: getLinkImageDeal(pDeal),
        country: '',
      };
      this.openShareModal = true;
    },
  },
};
</script>

<style>
@import "/assets/css/bonaufApp.css";
</style>

<style scoped>
  .price-text {
    cursor: pointer;
  }
</style>
