<template>
  <div class="tabs-container clearfix" id="flights_details_wrap">
        <div id="flights-wrap" class="loader">
            <div class="tabs-container-flight clearfix flight_with_places" id="flight-box-1272195" rel="1272195">
                <!-- flight exit -->
                <div class="tabs-container-flight-departure clearfix">
                    <div class="tabs-container-airplane"><img :src="air_logo_depature" alt="air-logo-departure"  width="64" height="64"/></div>
                    <div class="tabs-container-flight-content">
                        <div class="line">
                            <strong>טיסה הלוך (ישירה)</strong>
                        </div>
                        <div class="box_flight_dates">
                            <span class="grey_flight">המראה:</span>
                            {{Dep_FL_Date}},
                            <!-- dd/MM/yyyy-->
                            {{Dep_FL_Dep_Hour}},
                            ת"א<br />

                            <span class="grey_flight">נחיתה:</span>
                            {{Dep_FL_Date}},
                            <!-- dd/MM/yyyy-->
                            {{Dep_FL_Arrv_Hour}},
                            באטומי<br />

                            <span class="grey_flight">חברה:</span> {{Dep_arl_Name}}<br />

                            <span class="grey_flight">מספר טיסה:</span> {{Dep_FL_AIRLINE}}{{Dep_FL_Flt_Number}} <br />
                            כולל כבודה עד {{Dep_FL_Max_Weight}} ק"ג
                            - {{Dep_meal}}
                            <!-- ללא ארוחה בטיסה/  עם ארוחה בטיסה -->
                            <!--no meal / with meal-->
                        </div>
                    </div>
                </div>

                <!-- flight return -->
                <div class="tabs-container-flight-departure clearfix">
                    <div class="tabs-container-airplane"><img :src="air_logo_arrive" alt="air-logo-arrive"  width="64" height="64"/></div>
                    <div class="tabs-container-flight-content">
                        <div class="line">
                            <strong>טיסה חזור (ישירה)</strong>
                        </div>
                        <div class="box_flight_dates">
                            <span class="grey_flight">המראה:</span>
                            {{Arr_FL_Date}},
                            <!-- dd/MM/yyyy-->
                            {{Arr_FL_Dep_Hour}},
                            ת"א<br />

                            <span class="grey_flight">נחיתה:</span>
                            {{Arr_FL_Date}},
                            <!-- dd/MM/yyyy-->
                            {{Arr_FL_Arrv_Hour}},
                            באטומי<br />

                            <span class="grey_flight">חברה:</span> {{Arr_arl_Name}}<br />

                            <span class="grey_flight">מספר טיסה:</span> {{Arr_FL_AIRLINE}}{{Arr_FL_Flt_Number}} <br />
                            כולל כבודה עד {{Arr_FL_Max_Weight}} ק"ג
                            - {{Arr_meal}}
                            <!-- ללא ארוחה בטיסה/  עם ארוחה בטיסה -->
                            <!--no meal / with meal-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

const { VUE_APP_DATE_SLASH_FORMAT } = process.env;

export default {
  components: {},
  data() {
    return {
      lang: 'he',
      air_logo_depature: '',
      Dep_FL_Date: '',
      Dep_FL_Dep_Hour: '',
      Dep_FL_Arrv_Hour: '',
      Dep_arl_Name: '',
      Dep_FL_AIRLINE: '',
      Dep_FL_Flt_Number: '',
      Dep_FL_From_Terminal: '',
      Dep_FL_Max_Weight: '',
      Dep_meal: '',

      air_logo_arrive: '',
      Arr_FL_Date: '',
      Arr_FL_Dep_Hour: '',
      Arr_FL_Arrv_Hour: '',
      Arr_arl_Name: '',
      Arr_FL_AIRLINE: '',
      Arr_FL_Flt_Number: '',
      Arr_FL_From_Terminal: '',
      Arr_FL_Max_Weight: '',
      Arr_meal: '',
    };
  },
  watch: {
    appDealPageData() {
      this.fillData();
    },
  },
  computed: {
    ...mapGetters({
      appDealPageData: 'GET_APP_DEAL_DATA',
    }),
  },
  async created() {
    if (!this.$route.query.dealId) return;
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('FETCH_APP_DEAL_PAGE_DATA', { dealId: this.$route.query.dealId, lang: this.lang });
    },
    fillData() {
      if (!this.appDealPageData) return;
      this.air_logo_depature = this.appDealPageData?.appDealExt?.AirlineLogoOut || '';
      this.Dep_FL_Date = this.convertNormalFormat(this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Date || '');
      this.Dep_FL_Dep_Hour = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Dep_Hour || '';
      this.Dep_FL_Arrv_Hour = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Arrv_Hour || '';
      this.Dep_arl_Name = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.arl_Name || '';
      this.Dep_FL_AIRLINE = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_AIRLINE || '';
      this.Dep_FL_Flt_Number = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Flt_Number || '';
      this.Dep_FL_From_Terminal = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.Fl_From_Terminal || '';
      this.Dep_FL_Max_Weight = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Max_Weight || '';
      const DepMeal = this.appDealPageData?.roundTripFlight?.FlightDetail?.[0]?.FL_Meal || '';
      this.Dep_meal = DepMeal === 'No' ? 'ללא ארוחה בטיסה' : 'כולל ארוחה בטיסה';

      this.air_logo_arrive = this.appDealPageData?.appDealExt?.AirlineLogoIn || '';
      this.Arr_FL_Date = this.convertNormalFormat(this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Date || '');
      this.Arr_FL_Dep_Hour = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Dep_Hour || '';
      this.Arr_FL_Arrv_Hour = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Arrv_Hour || '';
      this.Arr_arl_Name = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.arl_Name || '';
      this.Arr_FL_AIRLINE = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_AIRLINE || '';
      this.Arr_FL_Flt_Number = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Flt_Number || '';
      this.Arr_FL_From_Terminal = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.Fl_From_Terminal || '';
      this.Arr_FL_Max_Weight = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Max_Weight || '';
      const ArrMeal = this.appDealPageData?.roundTripFlight?.FlightDetail?.[1]?.FL_Meal || '';
      this.Arr_meal = ArrMeal === 'No' ? 'ללא ארוחה בטיסה' : 'כולל ארוחה בטיסה';
    },
    convertNormalFormat(pDate) {
      const date = dayjs(pDate).format(VUE_APP_DATE_SLASH_FORMAT);
      return date;
    },
  },
};
</script>

<style>

</style>
