<template>
  <div>
    <div v-if="device != 'desktop'">
      <mobile-head-banner :stage="bookingStage" />
      <div class="mobail_part">
        <FlightDetail :flights="product.flights" />
      </div>
    </div>
    <div class="container p-0">
      <div class="new_booking_main_area dVacation-pack booking_mobile_part mt-3">
        <div class="booking_details_three">
          <div class="price_details">
            <div class="price_details_heading" v-if="device === 'desktop'">
              <h3>{{ $t("booking.reservation-summery") }}</h3>
              {{ $route.params.flights }}
            </div>
            <div class="price_details_main_body">
              <div class="price_details_body">
                <div class="product-page-flight p-1" v-if="flightItems.length > 0">
                  <FlightOnlyItem v-for="(item, inx) in flightItems" :key="inx" :flight="item"
                    :categoryName="categoryName" type="product" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div  class="login-box pb-5 m-auto" >
            <MemberLogin :oldShekelPrice="oldShekelPrice" :totalShekelPrice="totalShekelPrice"/>
            <MemberRegister/>
          </div> -->
          <div class="p-3 mb-3 w-100 card" v-if="isOdysseySite">
            <internal-agent-info @updateInternalAgentInfo="changeAgentInfo" />
          </div>

          <div class="passenger_detail" ref="passenger">
            <div>
              <div class="passenger_details_main_area booking_mobile_part mt-3">
                <div v-if="device == 'desktop'">
                  <div class="passenger_box_one"
                    :style="`background-image: url(${imageDomain}/assets/img/passenger-box-banner-two.jpg?speedsize=w_1139);`">
                    <h2>
                      {{ $t("booking.passenger-details")
                      }}<span class="icon"><i class="fas fa-user"></i></span>
                    </h2>
                  </div>
                </div>
                <div class="container guest_details_body" v-else>
                  <h3>
                    {{ $t('booking.guest-desc') }}
                    <span class="icon"><i class="fas fa-user"></i></span>
                    <div class="guest_title_rmk">{{ $t('booking.guest-title-rmk') }}</div>
                  </h3>
                  <!-- <p>{{ $t("booking.guest-desc") }}</p> -->
                </div>
                <UserListFlight :flight="flight" :index="0" @updatedUser="updatingUser" />
              </div>
            </div>
          </div>

          <div ref="payer">
            <payer-information @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
          </div>

          <div ref="checkTerms" class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5">
            <div class="booking_details_three">
              <div class="price_details">
                <div class="price_details_heading" v-if="device == 'desktop'">
                  <h3>{{ $t("booking.reservation-terms") }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body">
                    <div class="terms_box">
                      <div class="form-check">
                        <label :class="['form-check-label', 'pr-5', !checkedTerms ? 'danger' : '']">
                          <input type="checkbox" class="form-check-input" v-model="checkedTerms" />{{
                            $t("booking.i-agree-to") }}
                          <strong
                            @click="showLinkInfo(`/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99`)">{{
                              $t('booking.agree-desc') }}</strong>
                          {{ $t('booking.read-desc') }}
                          <strong
                            @click="showLinkInfo('/%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C')">{{
                              $t('booking.read-desc-corona') }}</strong>
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input type="checkbox" class="form-check-input" v-model="checkedPromotion" />
                          {{ $t("booking.email-receive-agree") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input type="checkbox" class="form-check-input" v-model="checkedInsurance" />
                          {{ $t("booking.telephone-provide-agree") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="total_booking_price" v-if="device == 'desktop'">
                <div class="d-flex justify-content-between">
                  <div class="price_box m-auto">
                    <h3 class="font-weight-bold d-flex">
                      {{ totalPriceLabel }} <span class="mt-3 ml-2"> : {{ $t("booking.total-payment") }}</span>
                    </h3>
                    <div class="mr-5">
                      <h3 v-if="isFcAgentMarketerMode" class="text-center">{{ $t('booking.clerk-name') }}: {{
                        marketerClerkName }}</h3>
                      <p class="align-self-end text-dark">{{ $t("booking.notice-final-exchange") }}</p>
                      <p v-if="isMarketerSite" class="align-self-end text-dark">{{ $t("booking.phone-commission") }}</p>
                    </div>
                  </div>
                  <button class="btn-booking" @click="BookingNow" :disabled="disableBook">
                    {{ $t("search-result.book-now") }}
                  </button>
                </div>
              </div>
              <div class="total_payment" v-else>
                <div class="container">
                  <div class="d-flex justify-content-around">
                    <div class="h2 mb-0 align-self-center">
                      <span class="icon" @click="showNotice()"><i class="fas fa-exclamation-circle text-info"></i></span>
                    </div>
                    <div class="d-flex">
                      <h4>
                        <span>{{ $t("booking.total-payment") }}</span>{{ totalPriceLabel }}
                      </h4>
                    </div>
                    <div>
                      <h6 v-if="isFcAgentMarketerMode" class="m-0 text-center">{{ $t('booking.clerk-name') }}: {{
                        marketerClerkName }}</h6>
                      <button class="btn" @click="BookingNow" :disabled="disableBook">
                        {{ $t("search-result.book-now") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="countDownModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{
          $t("booking.go-product-page")
        }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoProductPage(); ok()">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
    <b-modal id="actionModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice') }}</h3>
        </div>
      </template>
      <template>
        <h5 :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t('booking.modal-delaying') }}</h5>
        <div class="actionContainer justify-content-between">
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToContinue')"><img :src="`${imageDomain}/assets/img/next.jpg`"
                  class="col-6 icon_box" width="25" height="25" alt="next" />
                <h6 class="m-auto">{{ $t('booking.modal-continue') }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToCall')"><img :src="`${imageDomain}/assets/img/phoneCall.png`"
                  class="col-6 icon_box " width="25" height="25" alt="phone-call" />
                <h6 class="m-auto">{{ $t('booking.modal-call', { phoneNumber: phoneNumber }) }}</h6>
              </b-link>
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="text-center">
              <b-link @click.prevent="delayAction('ToBack')"><img :src="`${imageDomain}/assets/img/previous.jpg`"
                  class="col-6 icon_box " width="25" height="25" alt="previous" />
                <h6 class="m-auto">{{ $t('booking.modal-back') }}</h6>
              </b-link>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal id="extraLinkInfo" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :ok-only="true"
      :hide-footer="true">
      <template>
        <iframe id="linkInformation" title="Extra Inforamtion" :src="linkInfo">
        </iframe>
      </template>
    </b-modal>
    <b-toast id="countDown" toaster="b-toaster-top-center" variant="warning" :autoHideDelay="maxSec * 1000" solid
      v-model="showCountDown" :classes="[lang == 'he' ? 'rtl' : 'ltr']">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
        </div>
      </template>
      {{ $t("booking.go-product-page") }}
    </b-toast>
    <b-modal id="actionRetryDebit" centered size="xl" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true"
      :ok-only="true" :hide-footer="true">
      <template>
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t('booking.notice-retry') }}</h3>
        </div>
      </template>
      <template>
        <payer-information @updatePayer="updatingPayer" v-if="!bypassPaymentState" />
        <div :class="['d-flex m-3 w-25', lang == 'he' ? 'float-left' : 'float-right']">
          <b-button class="m-3" block @click="retryBookWithNewPayerInfo"
            variant="outline-success">{{ $t("booking.retry-booking") }}</b-button>
          <b-button class="m-3" block @click="$bvModal.hide('actionRetryDebit')"
            variant="outline-success">{{ $t("booking.close") }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BToast, BModal, BButton, BLink } from 'bootstrap-vue';
import dayjs from 'dayjs';
import gMixin from '@/utils/mixins';
import googleAnalytics from '@/utils/googleAnalytics';
import imageUrlMixin from '@/utils/imageUrlMixin';
import mixinTheme from '@/components/mixins/mixinTheme';

const { VUE_APP_GOOGLE_PHONE_NUMBER, VUE_APP_FACEBOOK_PHONE_NUMBER } = process.env;

export default {
  components: {
    BToast,
    BModal,
    BButton,
    BLink,
    MobileHeadBanner: () => import('@/components/booking/atoms/MobileHeadBanner'),
    UserListFlight: () => import('@/components/booking/atoms/UserListFlight'),
    FlightDetail: () => import('@/components/productPage/FlightDetail'),
    PayerInformation: () => import('@/components/booking/atoms/PayerInformation'),
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
    InternalAgentInfo: () => import('@/components/booking/InternalAgentInfo'),
    // MemberLogin: () => import('@/components/atoms/MemberLogin'),
    // MemberRegister: () => import('@/components/atoms/MemberRegister'),
  },
  mixins: [gMixin, googleAnalytics, imageUrlMixin, mixinTheme],
  computed: {
    ...mapGetters({
      categoryState: 'GET_CURRENT_CATEGORY',
      productState: 'GET_PRODUCT',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      user: 'GET_MEMBER',
      typeChannel: 'GET_TYPE_CHANNEL',
      discountAmountNis: 'GET_DISCOUNT_AMOUNT_NIS',
      discountAmount: 'GET_DISCOUNT_AMOUNT',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      marketerClerkName: 'GET_ODYSSEY_AGENT_CLERK_NAME',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
      isMobileApp: 'GET_STATE_MOBILE_APP',
    }),
    oldShekelPrice() {
      const { product } = this;
      if (!product) return 0;
      return Number(product.priceNIS);
    },
    totalShekelPrice() {
      const { product, discountAmountNis } = this;
      if (!product) return 0;
      // if (!discountAmountNis) return product.priceNIS;
      return Number(product.priceNIS) - Number(discountAmountNis);
    },
    totalPrice() {
      const { discountAmount } = this;
      return this.internalAgentInfo ? this.internalAgentInfo.DiscountAmountByAgent : this.product.priceTotal - discountAmount;
    },
    totalPriceLabel() {
      const { totalPrice, lang } = this;
      return lang === 'he' ? `( ₪${this.product.priceNIS - this.discountAmountNis} ) ${this.getPriceWithSymbol(this.product.cc, totalPrice)}` : `${this.getPriceWithSymbol(this.product.cc, totalPrice)}`;
    },
    scrollTopCheckedTerms() {
      const offsetTopCheck = this.$refs?.checkTerms?.offsetTop || 0;
      return offsetTopCheck;
    },
    scrollTopPassenger() {
      const offsetTopPassenger = this.$refs?.passenger?.offsetTop || 0;
      return offsetTopPassenger;
    },
    scrollTopPayer() {
      const offsetTopPayer = this.$refs?.payer?.offsetTop || 0;
      return offsetTopPayer;
    },
    isMarketer() {
      return this.marketerId && this.marketerId !== '';
    },
    phoneNumber() {
      const utmSource = this.$route.query.utm_source;
      const phone = utmSource === 'google' ? VUE_APP_GOOGLE_PHONE_NUMBER : VUE_APP_FACEBOOK_PHONE_NUMBER;
      // eslint-disable-next-line no-nested-ternary
      return utmSource ? phone : this.hostData?.phoneNumber || this.whiteLabel.phoneNumber;
    },
  },
  watch: {
    // lang: 'updateLabelWithLang',
    // discountAmountNis: 'updateLabelWithLang',
    // discountAmount: 'updateLabelWithLang',
  },
  data() {
    return {
      query: null,
      bookingStage: 2,

      product: null,
      category: null,
      flightItems: [],
      categoryName: '',
      flight: null,
      userList: [],

      payerInfo: {
        firstNamePayer: '',
        lastNamePayer: '',
        phonePayer: '',
        emailPayer: '',
        idCardNumberPayer: '',
        // CreditCardExpirYear: '',
        // CreditCardExpirMonth: '',
        NoOfPayment: '1',
      },

      // totalPrice: 0,
      // totalPriceLabel: '$0',

      showCountDown: false,
      maxSec: 10,

      maxDelay: 60 * 60,

      checkedTerms: false,
      checkedPromotion: true,
      checkedInsurance: true,

      availableUsers: false,
      availablePayer: false,

      disableBook: false,
      timeID: -1,
      internalAgentInfo: null,

      linkInfo: '',
    };
  },
  async created() {
    this.disableBook = false;
    this.$emit('setBookingStage', 2);
    await this.fetchData();
    // this.totalPrice = this.product.priceTotal;
    // this.totalPriceLabel = this.lang === 'he' ? `( ₪${this.product.priceNIS - this.discountAmountNis} ) $${this.product.price - this.discountAmount}` : `$${this.product.price - this.discountAmount}`;
    this.categoryName = this.category.code;

    const retry = window.sessionStorage.getItem('retryState'),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      { payer } = dataBook.data,
      { sendingStage } = dataBook;

    if (retry !== 'null' && sendingStage !== 'init' && !this.bypassPaymentState) {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.payerInfo.firstNamePayer = name[0];
        this.payerInfo.lastNamePayer = name[1];
      }
      this.payerInfo.phonePayer = payer.mobile;
      this.payerInfo.emailPayer = payer.email;
      this.payerInfo.idCardNumberPayer = payer.IdenticationNumber;
      // this.payerInfo.CreditCardExpirYear = payer.CreditCardExpirYear;
      // this.payerInfo.CreditCardExpirMonth = payer.CreditCardExpirMonth;
      this.payerInfo.NoOfPayment = Number(payer.NoOfPayment);
    }

    // if (!window.location.host.includes('vercel')) {
    //   const id = window.sessionStorage.getItem('BookingDataID'),
    //     flight = this.product.flights[0].FlightDetail[0],
    //     destCode = flight?.FL_To_Route,
    //     destName = flight?.translations?.flightDestinationName?.[destCode].he || flight?.translations?.flightDestinationName?.[destCode].en;
    //   this.gtag('event', 'add_to_cart', {
    //     items: [
    //       {
    //         id, // please assign number
    //         name: `${flight.arl_Name} - ${destName}`,
    //         list_name: '',
    //         brand: '',
    //         category: '',
    //         variant: '',
    //         list_position: 1,
    //         quantity: 1,
    //         price: this.totalPrice,
    //       },
    //     ],
    //   });
    // }
    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    const retry = JSON.parse(window.sessionStorage.getItem('retryState')),
      dataBook = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')),
      // eslint-disable-next-line no-unused-vars
      { payer } = dataBook.data,
      { sendingStage } = dataBook;
    if (retry == null && sendingStage !== 'init') {
      // this.$bvToast.show("countDown");
      this.$bvModal.show('countDownModal');
      const oneself = this;
      this.timeID = setInterval(() => {
        oneself.maxSec -= 1;
        if (oneself.maxSec === 0) {
          oneself.gotoProductPage();
        }
      }, 1000);
    }

    setTimeout(() => {
      this.$bvModal.show('actionModal');
    }, this.maxDelay * 1000);

    dataBook.sendingStage = 'update';
    // window.sessionStorage.setItem('BookingDetailInfo', JSON.stringify(dataBook));

    if (this.availableEmitEventGA4ByDomain()) {
      const id = window.sessionStorage.getItem('BookingDataID');
      const flight = this.product.flights[0].FlightDetail[0],
        destCode = flight?.FL_To_Route,
        destName = flight?.translations?.flightDestinationName?.[destCode].he || flight?.translations?.flightDestinationName?.[destCode].en;
      const currencyCode = this.convertCurrencyCode(this.product.cc);
      const affiliation = this.isMobileApp ? 'BO NAUF' : 'Flying Carpet';
      this.gtag('event', 'add_to_cart', {
        currency: currencyCode,
        value: this.totalPrice,
        items: [
          {
            item_id: id,
            item_name: `${flight.arl_Name} - ${destName}`,
            affiliation,
            coupon: '',
            discount: '',
            index: 0,
            item_brand: '',
            item_category: destName,
            item_category2: this.getCheckInOut(),
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_list_id: '',
            item_list_name: '',
            item_variant: '',
            location_id: '',
            price: this.totalPrice,
            quantity: 1,
          },
        ],

        // items: [
        //   {
        //     id, // please assign number
        //     name: `${flight.arl_Name} - ${destName}`,
        //     list_name: '',
        //     brand: '',
        //     category: '',
        //     variant: '',
        //     list_position: 1,
        //     quantity: 1,
        //     price: this.totalPrice,
        //   },
        // ],
      });
    }

    if (retry !== null && sendingStage !== 'init') {
      if (retry.readOnly) {
        this.$bvModal.show('actionRetryDebit');
      }
    }
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
  methods: {
    async fetchData() {
      const localProduction = JSON.parse(
        window.sessionStorage.getItem('production'),
      );
      this.category = this.categoryState || localProduction.category;
      this.product = this.productState || localProduction;
      if (this.product.flights.length > 0) {
        if (this.discounted) {
          this.flightItems = this.product.flights.filter(
            (item) => `${item.FlightDetail[0].FL_ID}${item.FlightDetail[1].FL_ID}`
              === this.$route.query.flights,
          );
        } else {
          this.flightItems = this.product.flights;
        }
      }
      this.flight = {
        ...this.product.generationInfo,
        departureDate: this.flightItems?.[0]?.FlightDetail?.[0]?.FL_Date || dayjs(),
      };
    },
    updateLabelWithLang() {
      // this.totalPriceLabel = this.lang === 'he' ? `( ₪${this.product.priceNIS - this.discountAmountNis} ) $${this.product.price - this.discountAmount}` : `$${this.product.price - this.discountAmount}`;
    },
    updatingUser(updatedUser) {
      const user = this.userList.filter(
        (pUser) => pUser.id === updatedUser.id && pUser.groupID === updatedUser.groupID,
      )[0];
      if (user !== undefined) {
        Object.assign(user, updatedUser);
      } else {
        this.userList.push(updatedUser);
      }
    },
    updatingPayer(updatedPayer) {
      this.payerInfo = updatedPayer;
      this.availablePayer = this.isAgencyLogin || updatedPayer.available;
    },
    formattingBookData() {
      if (this.bypassPaymentState) this.availablePayer = true;
      let bookInfo = {};
      const production = JSON.parse(window.sessionStorage.getItem('production'));
      const retryState = JSON.parse(window.sessionStorage.getItem('retryState'));
      if (retryState && retryState.readOnly) {
        this.availableUsers = true;
        bookInfo = JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')).data;
        bookInfo.payer = this.bypassPaymentState ? null : {
          firstName: this.payerInfo.firstNamePayer,
          lastName: this.payerInfo.lastNamePayer,
          email: this.payerInfo.emailPayer,
          mobile: this.payerInfo.phonePayer,
          IdenticationNumber: this.payerInfo.idCardNumberPayer,
          // CreditCardExpirMonth: this.payerInfo.CreditCardExpirMonth,
          // CreditCardExpirYear: this.payerInfo.CreditCardExpirYear,
          NoOfPayment: Number(this.payerInfo.NoOfPayment),
        };
      } else {
        let userID = 0;
        const userIdList = [];
        this.availableUsers = true;
        this.userList.forEach((user) => {
          userID += 1;
          user.id = String(userID);
          userIdList.push(String(userID));
          if (!user.validate) this.availableUsers = false;
        });
        const outwardFlight = production.flights[0].FlightDetail[0],
          inwardFlight = production.flights[0].FlightDetail[1];

        const paxList = [];
        this.userList.forEach((user) => {
          const clone = { ...user };
          const birthday = dayjs(clone.birthDate, 'DD-MM-YYYY').format('YYYY-MM-DD').toString();
          clone.birthDate = birthday;
          delete clone.groupID;
          paxList.push(clone);
        });

        bookInfo = {
          packageId: production.packId,
          hotel_shift_1: production.hotel_shift_1,
          duration: production.duration_1,
          discountPercent: production.discountPercent || null,
          price: this.totalPrice,
          priceNIS: this.product.priceNIS,
          currency: this.product.cc,
          packSelectionId: production.packSelectionId,
          paxList,
          outwardFlight: {
            flightId: outwardFlight.FL_ID,
            departureCityCode: outwardFlight.FL_From_Air_Port,
            departureDate: outwardFlight.FL_Date,
            departureTime: outwardFlight.FL_Dep_Hour,
            arrivalCity: outwardFlight.FL_To_Air_Port,
            class: this.flightItems[0].Class1,
            paxIds: userIdList,
          },
          inwardFlight: {
            flightId: inwardFlight.FL_ID,
            departureCityCode: inwardFlight.FL_From_Air_Port,
            departureDate: inwardFlight.FL_Date,
            departureTime: inwardFlight.FL_Dep_Hour,
            arrivalCity: inwardFlight.FL_To_Air_Port,
            class: this.flightItems[0].Class2,
            paxIds: userIdList,
          },
          payer: this.bypassPaymentState ? null : {
            firstName: this.payerInfo.firstNamePayer,
            lastName: this.payerInfo.lastNamePayer,
            email: this.payerInfo.emailPayer,
            mobile: this.payerInfo.phonePayer,
            IdenticationNumber: this.payerInfo.idCardNumberPayer,
            // CreditCardExpirMonth: this.payerInfo.CreditCardExpirMonth,
            // CreditCardExpirYear: this.payerInfo.CreditCardExpirYear,
            NoOfPayment: Number(this.payerInfo.NoOfPayment),
          },
          sendPromotionalProductMails: this.checkedPromotion,
          sendPromotionalInsuranceMails: this.checkedInsurance,
        };
      }
      if (this.isOdysseySite) {
        bookInfo.DiscountAmountByAgent = this.internalAgentInfo?.DiscountAmountByAgent || 0;
        bookInfo.BypassPaymentByAgent = this.internalAgentInfo?.BypassPaymentByAgent || false;
      }
      const data = {
        data: bookInfo,
        sendingStage: 'update',
      };
      return data;
    },
    async sendBookingInformation() {
      const data = this.formattingBookData();
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);
      return response;
    },
    checkingCheckedTerms() {
      const availableBooking = this.checkedTerms;
      if (!availableBooking) {
        document.querySelector('.st-content').scrollTo(0, this.scrollTopCheckedTerms);
        const message = this.$t('booking.toast-confirm-reservation');
        this.$bvToast.toast(
          message,
          {
            title: this.$t('booking.toast-information'),
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          },
        );
      }
      return availableBooking;
    },
    checkAvailableUserPayerInfo() {
      const availableBooking = this.availablePayer && this.availableUsers;
      if (!availableBooking) {
        let message = 'error payer or pax information';
        if (this.availablePayer) {
          message = this.$t('booking.toast-fill-pax-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPassenger);
        } else if (this.availableUsers) {
          message = this.$t('booking.toast-fill-payer-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPayer);
        } else if (!this.availablePayer && !this.availableUsers) {
          message = this.$t('booking.toast-pax-payer-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPassenger);
        }
        this.$bvToast.toast(message, {
          title: this.$t('booking.toast-information'),
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });

        this.disableBook = false;
        const data = this.formattingBookData();
        const retryState = JSON.parse(window.sessionStorage.getItem('retryState'));
        if (retryState && retryState.readOnly) {
          data.sendingStage = 'update';
        } else {
          data.sendingStage = 'init';
        }
        this.$store.dispatch('STORE_BOOKING_DATA', data);
      }
      return availableBooking;
    },
    checkStoreBookData(pResponse) {
      const availableBooking = pResponse.success;
      if (!availableBooking) {
        const message = this.$t('booking.toast-back-product');
        this.$bvToast.toast(
          message,
          {
            title: this.$t('booking.toast-information'),
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          },
        );
        this.disableBook = false;
        const oneself = this;
        setTimeout(async () => {
          // await this.ClosingWindow();
          const marketerURL = window.sessionStorage.getItem('marketerURL');
          oneself.$router.push({ path: marketerURL });
        }, 5000);
      }
      return availableBooking;
    },
    checkTransactionID(pResponse) {
      const availableBooking = pResponse.data.bookingTransactionId !== '' && pResponse.data.bookingTransactionId !== undefined && pResponse.data.bookingTransactionId !== null;
      if (!availableBooking) {
        const message = this.$t('booking.toast-back-product');
        this.$bvToast.toast(message, {
          title: this.$t('booking.toast-information'),
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
        const oneself = this;
        setTimeout(() => {
          const marketerURL = window.sessionStorage.getItem('marketerURL');
          oneself.$router.push({ path: marketerURL });
        }, 5000);
      }
      return availableBooking;
    },
    checkPaymentURL(pResponse) {
      const availableBooking = pResponse.success;
      if (!availableBooking) {
        if (pResponse.error?.response?.status === 400) {
          const message = this.$t('booking.toast-fill-payer-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPayer);
          this.$bvToast.toast(message, {
            title: pResponse.error?.response?.title,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          });
          this.disableBook = false;
        } else {
          this.showInternalServerError(pResponse);
        }
      }
      return availableBooking;
    },
    checkAvailableDebitCard(pResponse) {
      const { error } = pResponse.url;
      const availableBooking = !error;
      if (!availableBooking && error?.retryAllowed) {
        let message = 'error payer or pax information';
        if (!error?.retryPaymentMethodOnly) {
          message = this.$t('booking.toast-fill-pax-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPassenger);
        } else {
          message = this.$t('booking.toast-fill-payer-information');
          document.querySelector('.st-content').scrollTo(0, this.scrollTopPayer);
        }

        this.$bvToast.toast(message, {
          title: error?.code || 'Retry',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
        this.disableBook = false;
      } else if (!availableBooking && !error?.retryAllowed) {
        this.showInternalServerError(pResponse);
      }
      return availableBooking;
    },

    showInternalServerError(pResponse) {
      const marketerURL = window.sessionStorage.getItem('marketerURL');
      const message = this.devMode
        ? this.$t('booking.toast-server-internal-error')
        : pResponse.error?.message;
      this.$bvToast.toast(message, {
        title: 'Server Internal Error',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'danger',
        toaster: 'b-toaster-top-right',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      setTimeout(() => {
        const backUrl = this.marketerId !== '' ? '/' : marketerURL;
        this.$router.push(backUrl);
      }, 5000);
    },
    async BookingNow() {
      if (!this.checkingCheckedTerms()) return;
      this.disableBook = true;
      let response = { success: true, data: { bookingTransactionId: '', priceNIS: 0 } };

      const retry = window.sessionStorage.getItem('retryState'),
        oldState = JSON.parse(retry);
      // const saveBookData = true;
      // if (retry !== 'null') {
      //   const oldState = JSON.parse(retry);
      //   saveBookData = !oldState.readOnly;
      //   this.availableUsers = oldState.readOnly;
      // }

      const paramMarketerId = this.marketerId !== '' ? `marketerId=${this.marketerId}` : '';
      const paramChannel = this.typeChannel !== '' ? `&channel=${this.typeChannel}` : '';
      const paramFcAgentMode = this.isFcAgentMarketerMode ? '&fc-agent-mode' : '';

      this.formattingBookData();

      // this.availableUsers = oldState.readOnly;
      if (!this.checkAvailableUserPayerInfo()) return;

      // if (saveBookData) {
      //   response = await this.sendBookingInformation();
      // } else {
      //   response.data.bookingTransactionId = window.sessionStorage.getItem('BookingDataID');
      // }

      response = await this.sendBookingInformation();

      if (!this.checkStoreBookData(response)) return;

      if (!this.checkTransactionID(response)) return;

      const { origin } = window.location;
      const info = (this.bypassPaymentState) ? null : {
        email: this.payerInfo.emailPayer,
        mobile: this.payerInfo.phonePayer,
        firstName: this.payerInfo.firstNamePayer,
        lastName: this.payerInfo.lastNamePayer,
        // CreditCardExpirMonth: this.payerInfo.CreditCardExpirMonth,
        // CreditCardExpirYear: this.payerInfo.CreditCardExpirYear,
        IdenticationNumber: this.payerInfo.idCardNumberPayer,
        NoOfPayment: Number(this.payerInfo.NoOfPayment),
        successUrl: `${origin}/redirect-verified`,
        failureUrl: `${origin}/redirect-verified1`,
        cssUrl: `${origin}/assets/css/styleVerify.css?ver=1.3`,
      };

      this.$bvModal.hide('actionRetryDebit');

      /* window.removeEventListener("beforeunload", this.ClosingWindow);
      let verifyWindow = window.open(res.url.paymentUrl, "_self", "");
      verifyWindow.focus(); */
      window.sessionStorage.setItem('marketerId', this.marketerId);
      window.sessionStorage.setItem('typeChannel', this.typeChannel);
      window.localStorage.setItem('fcAgentMode', paramFcAgentMode);

      if (this.bypassPaymentState) {
        this.$router.push({ path: `/booking/card-verified/?${paramMarketerId}${paramChannel}${paramFcAgentMode}` });
      } else {
        const res = await this.$store.dispatch('GET_PAYMENT_URL', {
          payerInfo: info,
          bookID: response.data.bookingTransactionId,
          retryBook: oldState ? oldState.readOnly : false,
        });

        if (!this.checkPaymentURL(res)) return;
        if (!this.checkAvailableDebitCard(res)) return;

        window.sessionStorage.setItem('BookingDataID', res.url.bookingTransactionId);

        this.$store.dispatch('UPDATE_VERIFYING_URL', res.url.paymentUrl);
        this.$router.push({ path: `/booking/verifying-card/?${paramMarketerId}${paramChannel}${paramFcAgentMode}` });
      }
    },
    async ClosingWindow() {
      const data = this.formattingBookData();
      const bookingDataID = this.bookingDataID || window.sessionStorage.getItem('BookingDataID');
      this.$store.dispatch('SEND_CLOSING_NOTICE', {
        bookDataID: bookingDataID,
        data: JSON.stringify(data.data),
        withData: true,
      });
    },
    async gotoProductPage() {
      clearInterval(this.timeID);
      // await this.ClosingWindow();
      this.$bvModal.hide('countDownModal');
      this.$store.dispatch('REDIRECT_TO_PRODUCT');
      const marketerURL = window.sessionStorage.getItem('marketerURL');
      this.$router.push(marketerURL);
    },
    async delayAction(pActionKind) {
      const marketerURL = window.sessionStorage.getItem('marketerURL');
      switch (pActionKind) {
        case 'ToContinue':
          this.$bvModal.hide('actionModal');
          break;
        case 'ToBack':
          this.$bvModal.hide('actionModal');
          // await this.ClosingWindow();
          this.$store.dispatch('REDIRECT_TO_PRODUCT');
          this.$router.push(marketerURL);
          break;
        case 'ToCall':
          this.$bvModal.hide('actionModal');
          window.open(`tel:${this.phoneNumber}`);
          break;
        default:
      }
    },
    async retryBookWithNewPayerInfo() {
      this.checkedTerms = true;
      this.disableBook = true;
      this.BookingNow();
    },
    changeAgentInfo(info) {
      this.internalAgentInfo = { ...info };
    },
    showLinkInfo(pLink) {
      const { origin } = window.location;
      this.linkInfo = `${origin}${pLink}`;
      this.$bvModal.show('extraLinkInfo');
    },
    showNotice() {
      this.$bvToast.toast(this.$t('booking.notice-final-exchange'), {
        title: this.$t('product-page.notice'),
        toaster: 'b-toaster-bottom-center',
        autoHideDelay: 5000,
        variant: 'info',
      });
    },
  },
};
</script>

<style>
#b-toaster-top-center {
  top: calc(50vh - 70px);
}

.modal .modal-dialog {
  display: flex;
  font-family: "ploni", "Poppins", sans-serif;
  font-weight: 500;
}

#countDownModal.modal .modal-dialog {
  display: flex;
  font-family: 'ploni', 'Poppins', sans-serif;
  font-weight: 500;
}

#countDownModal.modal:before {
  display: none;
}

#countDownModal.modal .modal-body {
  display: flex;
}

/* .modal:before {
  display: none;
}
#countDownModal .modal .modal-body {
  display: flex;
} */

#actionModal {
  padding-left: 0px !important;
}

#actionModal .modal-dialog {
  padding-right: 15px;
}

.modal::before {
  height: auto !important;
}
</style>

<style scoped>
.login-box {
  width: fit-content;
  display: flex;
}

#countDown.ltr {
  direction: ltr;
}

#countDown.rtl {
  direction: rtl;
}

#countDown h5 {
  padding: 15px 5px;
  font-size: 15px;
}

#countDown h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.price_details_heading {
  padding: 20px 30px;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(1, 98, 172, 1) 0%,
      rgba(38, 135, 209, 1) 100%);
}

.price_details_main_body {
  padding: 30px 30px;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.price_details_heading h3 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0;
  padding: 0 0;
  text-transform: uppercase;
}

.price_details_body h4 {
  color: #000;
  text-transform: capitalize;
  font-size: 25px;
  margin-bottom: 2px;
}

.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}

.passenger_details_main_area .passenger_box_one {
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
}

.passenger_details_main_area .passenger_box_one h2 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  position: relative;
  font-size: 30px;
  text-transform: capitalize;
  padding-right: 45px;
  direction: ltr;
}

.passenger_details_main_area {
  color: #fff;
}

.passenger_details_main_area .passenger_box_one h2 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
}

.passenger_box_two {
  border-radius: 5px;
}

.terms_box .form-check {
  margin-bottom: 10px;
}

.terms_box .form-check label {
  font-size: 15px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: -1.25rem;
}

.form-check label strong {
  font-weight: 400;
  color: #0061ab;
}

.total_booking_price {
  background: #0161ab;
  padding: 30px 30px;
  box-sizing: border-box;
}

.total_booking_price .btn-booking {
  background: #ffffff;
  color: #0060aa;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 500;
}

.total_booking_price .btn-booking:hover {
  background: #528ab5;
}

.total_booking_price .price_box h3 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  font-size: 40px;
  direction: ltr;
}

.total_booking_price .price_box h3 span {
  display: block;
  font-size: 15px;
  text-align: right;
  margin-bottom: 2px;
}

.guest_details_body h3 {
  padding: 0 0 7px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  border-bottom: 2px solid #bfbfbf;
  margin-bottom: 10px;
  position: relative;
  padding-right: 25px;
}

.guest_details_body h3 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}

.guest_details_body p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #212529;
}

.icon_box {
  width: 100px;
  height: 100px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 7px 7px;
  justify-content: center;
  margin: 10px auto 15px;
  cursor: pointer;
}

.actionContainer {
  display: flex;
}

.danger {
  border-radius: 5px;
  border: solid 1px #f00;
  border-color: #FF5733;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(233 102 102 / 60%);
}

.total_payment .btn {
  background: #0061ab;
  color: #fff;
  text-transform: lowercase;
  padding: 0 14px;
  line-height: 0;
  height: 34px;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
}

#extraLinkInfo .modal-dialog {
  width: 100%;
  height: 90vh;
}

#extraLinkInfo .modal-dialog .modal-header {
  padding: 5px 30px;
  margin: 0px;
}

#extraLinkInfo .modal-dialog .modal-body {
  margin-top: 0px;
}

#extraLinkInfo .modal-dialog .modal-content {
  height: 90vh;
}

#extraLinkInfo .modal-dialog .modal-content iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .price_details_main_body {
    padding: 30px 1px;
  }
}

@media (max-width: 479px) {
  .price_details_main_body {
    padding: 0px;
  }

  #extraLinkInfo .modal-dialog .modal-header {
    padding: 5px 10px;
    margin: 0px;
  }

  .terms_box .form-check label {
    font-size: 12px;
    color: #212529;
  }

  .form-check {
    margin-bottom: 8px;
  }

  .form-check label strong {
    color: #212529;
    font-weight: bolder;
  }

  .total_payment {
    padding: 20px 0px 0px;
    overflow: hidden;
    box-shadow: 0px -2px 2px rgba(221, 221, 221, 0.5);
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 91;
  }

  .total_payment h4 {
    text-align: left;
    direction: ltr;
  }

  .total_payment h4 span {
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    text-align: right;
  }

  .login-box {
    width: fit-content;
    display: block;
  }
}
</style>
