<template>
  <div class="price-plan-page-top">
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <div class="row p-3 mx-3">
      <div class="col-md-2 align-self-center text-center">
        <b-button variant="primary" v-if="filteredPrices.length" @click="exportWord">{{ $t('price-plan.export-word') }}</b-button>
        <b-button class="mx-3" variant="primary" :disabled="isFileSending" v-if="filteredPrices.length" @click="showConfirmModal">
          {{ $t('price-plan.send-doc') }} <b-spinner v-if="isFileSending" small></b-spinner></b-button>
      </div>
      <div class="col-md-8">
        <price-plan-filter-panel v-if="availNightList.length && dateRange.length"
          :dateRange="dateRange"
          :availNightList="availNightList"
          :availMonthsList="availMonthsList"
          @sendFilteredData="updateFilterData"/>
      </div>
    </div>
    <div ref="sendDoc" class="d-none">
      <div style="direction: ltr;">
        <div style="text-align: center;width: 100%;" v-if="sendingFilteredList && sendingFilteredList.length">
          <h3>{{ `${sendingFilteredList[0].destinationCountryName['en']} - ${sendingFilteredList[0].destinationName['en']}` }}</h3>
          <h5>{{ `${sendingFilteredList[0].hotelName[lang]} ${sendingFilteredList[0].stars}*${sendingFilteredList[0].boardName}` }}</h5>
        </div>
        <price-plan-send-item v-for="(item, inx) in sendingFilteredList" :key="inx" :item="item" :chosenRoomTypes="selectedRoomTypes"/>
      </div>
    </div>
    <div ref="exportDoc">
      <price-plan-item v-for="(item, inx) in filteredPrices" :key="inx" :item="item"/>
    </div>
    <Footer/>

    <b-modal
      id="confirm-modal"
      centered
      size="sm"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="false"
      :ok-only="true"
    >
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :dir="lang==='he' ? 'rtl' : 'ltr'">
          <h3>{{ $t('product-page.notice') }}</h3>
        </div>
      </template>
      <div class="d-flex">
        <h5>Sent successfully</h5>
      </div>
    </b-modal>
    <b-modal
      id="composition-selection"
      centered
      size="lg"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="false"
      :ok-only="true"
    >
      <template #modal-header>
        <div class="d-flex flex-grow-1 align-items-baseline" :dir="lang==='he' ? 'rtl' : 'ltr'">
          <h3>{{ $t('login.confirm') }}</h3>
        </div>
      </template>
      <div class="d-flex" dir="ltr">
        <b-form-group label="Select room types to send:">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="selectedRoomTypes"
            name="flavour-2"
            :options="roomTypeList"
          >
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="success" @click="ok();sendingDoc()">
          OK
        </b-button>
        <b-button variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BModal, BSpinner,
  BFormGroup, BFormCheckboxGroup,
  // BFormCheckbox
} from 'bootstrap-vue';
import getBannerImage from '@/utils/getBannerImage';
import exportDoc from '@/utils/exportWordPDF';

export default {
  name: 'pricePlan',
  mixins: [getBannerImage, exportDoc],
  components: {
    BButton,
    BModal,
    BSpinner,
    BFormGroup,
    BFormCheckboxGroup,
    // BFormCheckbox,
    Banner: () => import('@/components/content/banner/BannerTheme0'),
    PricePlanItem: () => import('@/components/pricePlan/PricePlanItem'),
    PricePlanSendItem: () => import('@/components/pricePlan/PricePlanSendItem'),
    PricePlanFilterPanel: () => import('@/components/pricePlan/PricePlanFilterPanel'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
  },
  data() {
    return {
      query: this.$route.query,
      filterDateFrom: null,
      filterDateTo: null,
      filterDuration: null,
      filterMonth: null,
      selectedRoomTypes: [],
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      selectedPrices: 'GET_SELECTED_DEALS_FOR_MARKETER',
      device: 'GET_DEVICE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      homeLink: 'GET_HOME_LINK',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
      isFileSending: 'GET_IS_FILE_SENDING_STATE',
    }),
    hideCondition() {
      const { bypassPaymentState, limitDealsState, query } = this;
      return ((bypassPaymentState || limitDealsState) && query.secret !== null && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    },
    queryCategory() {
      return this.query.category || '';
    },
    queryDest() {
      return this.query.dest || '';
    },
    queryHotelId() {
      return this.query.hotelId || '';
    },
    queryNights() {
      return this.query?.nights?.split(',') || [];
    },
    queryDealIds() {
      return this.query?.dealId?.split(',') || [];
    },
    dateRange() {
      const { selectedPrices } = this;
      if (!selectedPrices) return [];
      const list = [...selectedPrices];
      const minDate = list.sort((a, b) => (new Date(a.departureDate) - new Date(b.departureDate)))[0].departureDate;
      const maxDate = list.sort((a, b) => (new Date(b.arrivalDate) - new Date(a.arrivalDate)))[0].arrivalDate;
      return [minDate, maxDate];
    },
    availNightList() {
      const { selectedPrices } = this;
      if (!selectedPrices) return [];
      const list = [...selectedPrices];
      return [...new Set(list.map((aItem) => aItem.nights))];
    },
    availMonthsList() {
      const { selectedPrices } = this;
      if (!selectedPrices) return [];
      const list = [...new Set(selectedPrices.map((aItem) => new Date(aItem.departureDate).getMonth()))];
      return list.sort((a, b) => a - b);
    },
    filteredPrices() {
      const { selectedPrices, filterDateFrom, filterDateTo, filterDuration, filterMonth } = this;
      if (!selectedPrices) return [];

      let list = [];
      if (filterDateFrom && filterDateTo) {
        list = [...selectedPrices].filter((l) => (new Date(l.departureDate) >= new Date(filterDateFrom) && new Date(l.arrivalDate) <= new Date(filterDateTo)));
      } else {
        list = [...selectedPrices];
      }

      if (filterDuration) {
        list = list.filter((l) => filterDuration.includes(l.nights));
      }
      if (filterMonth) {
        list = list.filter((l) => filterMonth.includes(new Date(l.departureDate).getMonth()));
      }

      list.sort((a, b) => (new Date(a.departureDate) - new Date(b.departureDate)));
      list.sort((a, b) => (a.nights - b.nights));
      return list;
    },
    sendingFilteredList() {
      const { filteredPrices } = this;
      const list = [...filteredPrices];

      list.sort((a, b) => (a.nights - b.nights));
      list.sort((a, b) => (new Date(a.departureDate) - new Date(b.departureDate)));
      return list;
    },
    roomTypeList() {
      const { filteredPrices } = this;
      if (!filteredPrices || !filteredPrices.length) return [];
      const { roomRates } = filteredPrices[0];
      const types = roomRates
        .sort((a, b) => a.adults - b.adults)
        .map((room) => ({
          value: `${room.adults},${room.children},${room.roomTypeCode}`,
          text: `${room.adults}+${room.children}(${room.roomTypeCode})` }));

      return types.filter((element, index) => {
        const firstIndex = types.findIndex((obj) => obj.value === element.value);
        const includeCond = element.value.includes('SG') || element.value.includes('TR3') || element.value.includes('DB') || element.value.includes('QU2')
        || element.value.includes('2F3') || element.value.includes('2F1');
        return index === firstIndex && includeCond;
      });
    },
  },
  watch: {
    roomTypeList() {
      this.selectedRoomTypes = this.roomTypeList.map((type) => type.value);
    },
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'price-plan');
    if (this.enableSearchAgentState) {
      window.location.href = this.homeLink;
    }
  },
  mounted() {
    if (this.queryDest && this.queryHotelId && this.queryNights.length) {
      this.$store.commit('SET_SEARCH_INFORMATION',
        {
          category: this.queryCategory,
          dest: this.queryDest,
          hotelId: this.queryHotelId,
          nights: this.queryNights,
          dealIds: this.queryDealIds,
        });
      this.$store.dispatch('FETCH_SELECTED_DEALS_FOR_MARKETER');
    }
  },
  methods: {
    updateFilterData(data) {
      this.filterDateFrom = data.from;
      this.filterDateTo = data.to;
      this.filterDuration = data.filterNights;
      this.filterMonth = data.filterMonths;
    },
    showConfirmModal() {
      this.$bvModal.show('composition-selection');
    },
  },
};
</script>
<style>
  .price-plan-page-top .vs__search, .price-plan-page-top .vs__dropdown-menu{
    color: black;
  }
</style>
