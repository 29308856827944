import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateProductPage',
  mixins: [bannerImageDestination, imageUrlMixin],
  data() {
    return {
      query: this.$route.query,
      // marketerId: '',
      subAgency: '',
      description: '',
      activeHotel: '',
      idxHotel: 0,
      flightItems: [],
      idxFlight: -1,
      flashingID: null,
      visibleMoreFlights: false,
      flashing: false,
      categoryCode: '',
      personString: '',
      betweenDateString: '',
      linkData: null,

      hotels: [],
      rating: 0,

      description_t1: '',
      description_t2: '',
      description_t3: '',
      description_t2_fixed: '',

      discounted: false,

      bookingStage: 1,
      stageLabel: [
        '',
        'product-page.package-details',
        'product-page.payment-details',
        'product-page.Order-Completion',
      ],
      imageList: [],

      hotelName: '',
      categoryName: '',
      destinationName: '',
      basisName: '',

      remarks: '',
      includes: null,
      availableAndroid: false,
      shareThisModal: false,

      availableFlight: true,
      cityCode: '',
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      isLoading: 'GET_LOADING_STATE',
      isDesktopApp: 'GET_BONAUF_DESKTOP_APP',
      product: 'GET_PRODUCT',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      destinationImages: 'GET_DESTINATION_IMAGES',
      typeChannel: 'GET_TYPE_CHANNEL',
      marketerId: 'GET_MARKETER_ID',
      landingInfo: 'GET_LANDING_INFO',
      subjectCategory: 'GET_SUBJECTS_CATEGORY',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
      isAppDealPage: 'GET_IS_APP_DEAL_PAGE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      optionsMaccabi: 'GET_OPTIONS_MACCABI',
      associatedProducts: 'GET_ASSOCIATED_PRODUCTS',
      isRemovePhoneButton: 'GET_IS_REMOVE_PHONE_BUTTON',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isTargetAgency: 'GET_IS_TARGET_AGENCY',
    }),
    availOptionDeadline() {
      return this.optionsMaccabi.availDeadline;
    },
    destinationImage() {
      if (!this.destinationImages || !this.product) return '';
      const dest = this.product.hotels[0].city || this.product.destination_1;
      const matchedItem = this.destinationImages.find((d) => d.code === dest);
      const imageUrls = matchedItem?.imageUrls;
      const url = (matchedItem && imageUrls && imageUrls.length) ? imageUrls[0] : '';
      return url;
    },
    isOrganizeTour() {
      return this.category.code === 'Organize_tour_packages';
    },
    includesText() {
      const { product } = this;
      const isTransfer = product.addSupplement.findIndex((d) => d.suppId === 49) > -1;
      const isBaggage = product.RemarkBusiness?.indexOf('Include Baggage') > -1 ?? false;
      let txt = '';
      if (isTransfer) txt += `, ${this.$t('search-result.transfers')}`;
      if (isBaggage) txt += `, ${this.$t('product-page.luggage')}`;
      return txt;
    },
    hideCondition() {
      const { bypassPaymentState, limitDealsState, isOdysseySite } = this;
      return (!isOdysseySite && (bypassPaymentState || this.device !== 'desktop' || this.isAppDealPage)) || limitDealsState;
    },
    remark() {
      // return this.product?.remarkTranslation?.[this.lang] ?? this.product?.remark ?? '';
      return this.product?.remark ?? '';
    },
  },
  watch: {
    product: 'getProducts',
  },
  methods: {
    analysisProductData() {
      this.idxHotel = this.product?.hotels.findIndex((el) => el.La_ID === this.activeHotel);
      this.product.category = this.category;
      this.categoryCode = this.product?.category.code;
      this.includes = {
        include: this.product?.Itinerary?.include ?? '',
        notInclude: this.product?.Itinerary?.notInclude ?? '',
        optional: this.product?.Itinerary?.optional ?? '',
      };

      this.updateLabelWithLang();

      this.hotels = this.product?.hotels;
      this.rating = Number(this.hotels[this.idxHotel]?.grade.substr(0, 1));

      this.getItineraryDetail();
      const checkIn = dayjs(this.product?.flights[this.idxFlight]?.FlightDetail[0]?.FL_Date).add(Number(this.product?.hotel_shift_1), 'day');
      this.getBetweenDateString();

      if (this.hotels.length === 0) {
        this.$bvModal.msgBoxOk(this.$t('search-result.no-hotels-message'), {
          title: this.$t('product-page.expire-title'),
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
      } else {
        this.description = this.product.hotels?.[this.idxHotel]?.desc ?? '';
        if (this.product.perspectiveUrls) this.imageList = this.imageList.concat(this.product.perspectiveUrls);
        if (this.product.galleryUrls) this.imageList = this.imageList.concat(this.product.galleryUrls);
        if (this.imageList.length === 0) this.imageList.push(...this.product.hotels[this.idxHotel]?.imgs);
        if (this.imageList.length === 0 && this.product?.productContent?.imageUrls.length) this.imageList.push(...this.product?.productContent?.imageUrls);
        if (this.imageList.length === 0) this.imageList.push('/assets/img/has_no_picture.jpg');
      }

      this.product.fromDate = checkIn;
      // this.product.discounted = Number(this.query.discount);
      // this.product.discountPercent = Number(this.query.discountPercent);
      const OFObject = this.product.flights[this.idxFlight]?.FlightDetail[0],
        IFObject = this.product.flights[this.idxFlight]?.FlightDetail[1];
      const outwardFlight = {
          flightId: OFObject.FL_ID,
          departureCityCode: OFObject.FL_From_Air_Port,
          departureDate: OFObject.FL_Date,
          departureTime: OFObject.FL_Dep_Hour,
          arrivalCity: OFObject.FL_To_Air_Port,
          class: this.product.flights[this.idxFlight].Class1,
          paxIds: [],
        },
        inwardFlight = {
          flightId: IFObject.FL_ID,
          departureCityCode: IFObject.FL_From_Air_Port,
          departureDate: IFObject.FL_Date,
          departureTime: IFObject.FL_Dep_Hour,
          arrivalCity: IFObject.FL_To_Air_Port,
          class: this.product.flights[this.idxFlight]?.Class2,
          paxIds: [],
        };
      this.product.outwardFlight = outwardFlight;
      this.product.inwardFlight = inwardFlight;

      let barCode = this.product.flights[this.idxFlight]?.FlightDetail[0]?.FL_AIRLINE;
      if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoDepart = this.product.airlineInfo[barCode];

      barCode = this.product.flights[this.idxFlight]?.FlightDetail[1]?.FL_AIRLINE;
      if (this.product.airlineInfo && this.product.airlineInfo[barCode]) this.airInfoReturn = this.product.airlineInfo[barCode];

      this.product.flights[this.idxFlight].FlightDetail[0].airlineInfo = this.airInfoDepart;
      this.product.flights[this.idxFlight].FlightDetail[1].airlineInfo = this.airInfoReturn;
    },
    getPersonString(adult, child, infant) {
      let str = ` ${this.$t('product-page.adult-number')}: ${adult}`;
      if (child) str += `, ${this.$t('product-page.child')}: ${child}`;
      if (infant) str += `, ${this.$t('product-page.infant')}; ${infant}`;
      return str;
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    getAvailableSeatsOfFlight(pFlight) {
      return Math.min(pFlight.avl1, pFlight.avl2);
    },
    getProducts() {
      if (this.product && this.product.flights && this.product.flights.length > 0) {
        // if (this.discounted) {
        //   this.flightItems = this.product.flights.filter(
        //     (item) => `${item.FlightDetail[0]?.FL_ID}${item.FlightDetail[1]?.FL_ID}` === this.query.flights,
        //   );
        // } else {
        //   this.flightItems = this.product.flights;
        // }

        this.flightItems = this.product.flights; // .sort((a, b) => (new Date(a.FlightDetail[0].FL_Date) - new Date(b.FlightDetail[0].FL_Date)));

        // check if exist the final flight
        // this.flightItems = this.product.flights.filter((flight) => flight.FlightDetail[0].FL_IsFinal && flight.FlightDetail[1].FL_IsFinal);
        // if (this.flightItems.length === 0) {
        //   this.flightItems = this.product.flights;
        // }

        // sort by final flight status
        this.flightItems.sort((a, b) => {
          if (a.FlightDetail[0].FL_IsFinal === b.FlightDetail[0].FL_IsFinal) {
            return b.FlightDetail[1].FL_IsFinal - a.FlightDetail[1].FL_IsFinal;
          }

          return b.FlightDetail[0].FL_IsFinal - a.FlightDetail[0].FL_IsFinal;
        });

        // set matched flight with query as selected
        let currFlightID = '';
        if (this.isAppDealPage || this.isMaccabiAgency || this.isAssociated) {
          this.idxFlight = this.flightItems.findIndex((flight) => `${flight.fl_id1}${flight.fl_id2}` === this.query.flights);
          if (this.idxFlight !== -1) currFlightID = this.query.flights;
        }

        // set the most vacant seat
        if (this.idxFlight === -1) {
          const firstFlight = this.flightItems[0];
          this.idxFlight = 0;

          // we should set the filter range according the first flight's status
          const conditionCase = (firstFlight.FlightDetail[0].FL_IsFinal ? 1 : 0) + (firstFlight.FlightDetail[1].FL_IsFinal ? 1 : 0);
          let max = this.getAvailableSeatsOfFlight(firstFlight);
          this.flightItems.forEach((flightItem, i) => {
            if (conditionCase === (flightItem.FlightDetail[0].FL_IsFinal ? 1 : 0) + (flightItem.FlightDetail[1].FL_IsFinal ? 1 : 0)) {
              const ctSeats = this.getAvailableSeatsOfFlight(flightItem);
              if (max < ctSeats) {
                max = ctSeats;
                this.idxFlight = i;
              }
            }
          });
          currFlightID = `${this.flightItems[this.idxFlight].fl_id1}${this.flightItems[this.idxFlight].fl_id2}`;
        }

        this.changeFlightIndex(this.idxFlight);

        // if (currFlightID !== this.query.flights) this.availableFlight = false;
        if (currFlightID === '') this.availableFlight = false;

        this.insertingAirlineInfo();
      }
    },
    insertingAirlineInfo() {
      this.flightItems.forEach((flight) => {
        flight.airlineInfo = null;
        let barCode = flight.FlightDetail[0]?.FL_AIRLINE;
        if (this.product.airlineInfo && this.product.airlineInfo[barCode]) {
          flight.FlightDetail[0].airlineInfo = this.product.airlineInfo[barCode];
        }

        barCode = flight.FlightDetail[1].FL_AIRLINE;
        if (this.product.airlineInfo && this.product.airlineInfo[barCode]) {
          flight.FlightDetail[1].airlineInfo = this.product.airlineInfo[barCode];
        }
        flight.translations = {
          flightDestinationName: this.product.translations?.flightDestinationName,
        };
      });
    },
    messageFromChild(pInfo) {
      switch (pInfo.kind) {
        case 'error-room-type':
          this.$bvToast.toast(
            pInfo.message,
            {
              title: this.$t('product-page.notice'),
              autoHideDelay: 10000,
              appendToast: true,
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
              bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
            },
          );
          break;
        case 'error-discount':
          this.$bvToast.toast(
            pInfo.message,
            {
              title: this.$t('product-page.notice'),
              autoHideDelay: 10000,
              appendToast: true,
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
              bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
            },
          );
          break;
        case 'upgradeRooms':
          this.personString = this.getPersonString(
            pInfo.paxes.adult, pInfo.paxes.child, pInfo.paxes.infant,
          );
          break;
        default:
      }
    },
    handleRatingApp() {
      this.availableAndroid = typeof Android !== 'undefined';
      // eslint-disable-next-line no-undef
      if (typeof Android !== 'undefined') Android.doNativeFunction();
    },
    changeFlightIndex(index, pDisable = false) {
      this.idxFlight = index;
      this.$store.commit('SET_FLIGHT_ID', `${this.flightItems[index].FlightDetail[0]?.FL_ID}${this.flightItems[index].FlightDetail[1]?.FL_ID}`);
      // this.product.flights = [JSON.parse(JSON.stringify(this.flightItems[index]))];
      // this.$store.commit('SET_PRODUCT', this.product);
      this.analysisProductData();

      if (!pDisable) {
        this.flashing = true;
        clearInterval(this.flashingID);
        this.flashingID = setInterval(() => {
          this.flashing = false;
          clearInterval(this.flashingID);
        }, 3000);
        setTimeout(() => {
          this.visibleMoreFlights = false;
          // if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, this.getUsableHeight());
        }, 1500);
      }
    },
    getUsableHeight() {
      // check if this page is within a app frame
      const ua = navigator.userAgent;
      // memoized values
      const isIphone = ua.indexOf('iPhone') !== -1 || ua.indexOf('iPod') !== -1;
      const isIpad = ua.indexOf('iPad') !== -1;
      const isAndroid = ua.indexOf('Android') !== -1;
      const isMobile = isIphone || isIpad || isAndroid;

      // compute the missing area taken up by the header of the web browser to offset the screen height
      return !isMobile ? 1100 : 1000;
    },
    getLogoInfo(pFlight, pType) {
      let url = pType === 'out' ? '/assets/img/bulgeri.jpg' : '/assets/img/blueair.jpg';
      if (pType === 'out') {
        url = pFlight.FlightDetail[0]?.airlineInfo?.desc2 || url;
      } else {
        url = pFlight.FlightDetail[1]?.airlineInfo?.desc2 || url;
      }
      return url;
    },
    convertDateFormat(pDate) {
      const format = 'DD-MM-YYYY';
      return dayjs(pDate).format(format);
    },
    getMaxWeight(pFlight, pType, pPackageType) {
      let weight = 0;
      if (pType === 'out') {
        if (pPackageType === 'bag') {
          weight = +pFlight.FlightDetail[0]?.FL_Max_Weight_Trolley;
        } else {
          weight = pFlight.FlightDetail[0]?.FL_Max_Weight;
        }
      } else if (pPackageType === 'bag') {
        weight = +pFlight.FlightDetail[1]?.FL_Max_Weight_Trolley;
      } else {
        weight = pFlight.FlightDetail[1]?.FL_Max_Weight;
      }
      return weight;
    },
    showShareThisModal() {
      this.shareThisModal = true;
    },
  },
};
