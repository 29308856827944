<template>
  <div>
    <div v-if="loading">
      <content-loading type="product" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
    }),
  },
  watch: {
    isLoading() {
      this.loading = this.isLoading;
    },
  },
  async mounted() {
    // await this.sendMailError('Response CR2k', JSON.stringify({ responseCR2K: window.location.href }));
    // const marketerId = window.sessionStorage.getItem('marketerId');
    // const typeChannel = window.sessionStorage.getItem('typeChannel');
    // const paramFcAgentMode = window.localStorage.getItem('fcAgentMode');
    // this.$store.commit('SET_MARKETER_ID', marketerId);
    // let path = window.location.href.replace('redirect-verified', 'booking/card-verified');
    // path += `&channel=${typeChannel}`;
    // if (marketerId !== '') path += `&marketerId=${marketerId}`;
    // window.sessionStorage.setItem('marketerId', '');
    // window.parent.location.href = `${path}${paramFcAgentMode}`;
    // window.parent.location.href = window.location.href.replace('redirect-verified', 'booking/card-verified');
  },
  methods: {
    async sendMailError(pStage, pResponse) {
      const body = `<h3>${pStage}</h3></Br>
        <div><pre>${pResponse}</pre></div>
        `;
      const notification = {
        subject: `${pStage}`,
        body,
      };
      await this.$store.dispatch('NOTIFY_ADMIN', notification);
    },
  },
};
</script>

<style scoped>

</style>
