import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

const getBannerImage = {
  mixins: [imageUrlMixin],
  computed: {
    ...mapGetters({
      imagesData: 'GET_BANNER_IMAGES',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
    }),
    showTopSlider() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.hide ?? false);
    },
    bannerImages() {
      let images = [];
      if (this.showTopSlider) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.imageWithHrefList;
        } else if (this.imagesData?.imagesByCode?.homeBanner?.length > 0) {
          images = this.imagesData?.imagesByCode?.homeBanner;
        } else {
          images = [
            { image: `${this.imageDomain}/assets/img/home-banner-image-02.jpg` },
            { image: `${this.imageDomain}/assets/img/home-banner-image-03.jpg` },
            { image: `${this.imageDomain}/assets/img/home-banner-image-01.jpg` },
          ];
        }
      }
      return (images) || [];
    },
    showMiddleBanner() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.hide ?? false);
    },
    middleBanner() {
      let images = [];
      if (this.showMiddleBanner) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList;
        } else {
          images = this.imagesData?.imagesByCode?.homeMiddleBanner ?? [];
        }
      }
      return images;
    },
    middleBannerHref() {
      return this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList?.[0]?.href
        || this.imagesData.imagesByCode?.DomesticSecondBanner?.[0]?.href || '';
    },
    showTopBanner() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.hide ?? false);
    },
    topBanner() {
      let images = [];
      if (this.showTopBanner) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.imageWithHrefList;
        } else {
          images = this.imagesData?.imagesByCode?.TopBanner;
        }
      }
      return (images) ? this.shuffle(images) : [];
    },
  },
  created() {
    if (!this.imagesData?.imagesByCode?.homeBanner) this.$store.dispatch('FETCH_BANNER_IMAGES', { page: 'home' });
  },
  methods: {
    shuffle(array) {
      let currentIndex = array.length, randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
  },
};

export default getBannerImage;
