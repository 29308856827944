import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'TemplateFlightOnly',
  mixins: [bannerImageDestination, imageUrlMixin],
  data() {
    return {
      subAgency: '',

      query: null,

      departureItem: null,
      returnItem: null,
      airInfoDepart: null,
      airInfoReturn: null,

      linkData: null,
      stageLabel: ['', 'product-page.package-details', 'product-page.payment-details', 'product-page.Order-Completion'],
      imageList: [],

      visibleMoreFlights: false,
      idxFlight: -1,
      extraFlights: [],

      packSelectionId: '', // test

      availableFlight: true,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      isLoading: 'GET_LOADING_STATE',
      product: 'GET_PRODUCT',
      packages: 'GET_PACKAGES',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      typeChannel: 'GET_TYPE_CHANNEL',
      marketerId: 'GET_MARKETER_ID',
      activeFlightId: 'GET_FLIGHT_ID',
      isAppDealPage: 'GET_IS_APP_DEAL_PAGE',
      associatedProducts: 'GET_ASSOCIATED_PRODUCTS',
    }),
    scrollTopFlight() {
      return this.$refs.flight.offsetTop - 60;
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
    // availableFlight() {
    //   const flightId = this.availableFlight ? this.activeFlightId : '';
    //   this.$store.commit('SET_FLIGHT_ID', flightId);
    // },
  },
  async created() {
    document.body.classList.add('productPage');

    this.$store.commit('SET_CURRENT_PAGE', 'product');
    this.query = this.$route.query;

    window.sessionStorage.setItem('marketerURL', this.$route.fullPath);
    window.sessionStorage.setItem('dealType', this.query.categoryId);

    window.sessionStorage.setItem('BookingDataID', '');
    await this.fetchData();
    this.updateLabelWithLang();
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
  methods: {
    async fetchData() {
      this.query = this.$route.query;
      await this.$store.dispatch('FETCH_AIRPORT');
      if (!this.query.packId || !this.query.adult || !this.query.categoryId || !this.query.dateFrom || !this.query.flights) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      await this.$store.dispatch('FETCH_PRODUCT_FLIGHT_ONLY', this.query);

      if (this.product.flights === undefined || this.product.flights.length === 0) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
        return;
      } else {
        const {
          categoryId,
          dateFrom,
          adult,
          child,
          infant,
        } = this.query;
        const toDate = dayjs(dateFrom, 'YYYY-MM-DD').add(this.product.duration_1, 'day').format('YYYY-MM-DD');
        const searchQuery = {
          dest: this.product.destination_1,
          fromDate: dateFrom,
          toDate,
          categoryId,
          adult,
          child,
          infant,
        };
        await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', searchQuery);
        this.getExtraFlights();
      }

      let currFlightID = '';
      if (this.isAppDealPage) {
        this.idxFlight = this.extraFlights.findIndex((flight) => `${flight.fl_id1}${flight.fl_id2}` === this.query.flights);
        if (this.idxFlight !== -1) currFlightID = this.query.flights;
      }

      if (this.idxFlight === -1) {
        this.idxFlight = this.extraFlights.findIndex((flight) => `${flight.fl_id1}${flight.fl_id2}` === `${this.product.flights[0].fl_id1}${this.product.flights[0].fl_id2}`);
        if (this.idxFlight !== -1) currFlightID = `${this.product.flights[0].fl_id1}${this.product.flights[0].fl_id2}`;
      }

      if (this.idxFlight === -1) {
        this.idxFlight = 0;
        currFlightID = `${this.extraFlights[0].fl_id1}${this.extraFlights[0].fl_id2}`;
      }

      this.changeFlightIndex(this.idxFlight);

      if (currFlightID !== this.query.flights) this.availableFlight = false;

      this.setFlightOfProduct();

      await this.fetchBannerImageByDestination();

      this.packSelectionId = this.product.packSelectionId;

      if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
    },
    getWeek(str) {
      const week = [
        this.$t('weekAllName.sun'),
        this.$t('weekAllName.mon'),
        this.$t('weekAllName.tue'),
        this.$t('weekAllName.wed'),
        this.$t('weekAllName.thu'),
        this.$t('weekAllName.fri'),
        this.$t('weekAllName.sat'),
      ];
      return week[dayjs(str).day()];
    },
    updateLabelWithLang() {
      const body = {
        dest: this.product.destination_1, // Object.keys(this.product.translations.flightDestinationName)[0],
        fromDate: this.query.dateFrom,
        toDate: this.product?.flights?.[0]?.FlightDetail[1]?.FL_Date,
        categoryId: this.query.categoryId,
        adult: this.query.adult,
        child: this.query.child,
        infant: this.query.infant,
      };
      if (this.query.utm_source) body.utm_source = this.query.utm_source;
      if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
      if (this.marketerId) body.marketerId = this.marketerId;

      if (this.product) {
        this.linkData = {
          text: this.product?.category?.name?.[this.lang] || this.product?.category?.name?.en,
          dest: this.product.translations?.flightDestinationName[this.product.destination_1][this.lang] || this.product?.destination_1_name,
          stage: this.$t(this.stageLabel[this.bookingStage]),
          searchQuery: `/search-result?${new URLSearchParams(body).toString()}`,
        };
      }
    },
    getExtraFlights() {
      this.extraFlights = [];
      this.packages.forEach((item) => {
        if (item.packId === Number(this.query.packId)) {
          const discountedFO = !!item.discountPercentFO;

          const { translations } = this.packages[0];

          const flightsFO = item.flights.map((d) => {
            let originalPrice = discountedFO ? item.originalPricesFO.priceByAge.find((dd) => dd.age_from === 0 && dd.age_to === 0).price : null;
            let matchingDiscountRuleFO = discountedFO ? item.matchingDiscountRuleFO : null;
            let prices = this.getPriceInfo(item.flightOnlyPrice);

            if (item.discountedFlightsFO) {
              const discountedFlightFOItem = item.discountedFlightsFO.find((elem) => elem.flightsId === `${d.fl_id1}${d.fl_id2}`);
              if (discountedFlightFOItem) {
                prices = this.getPriceInfo(discountedFlightFOItem.discountedPrice?.priceByAge) || null;
                originalPrice = discountedFlightFOItem.originalPrice?.priceByAge?.find((dd) => dd.age_from === 0 && dd.age_to === 0).price || null;
                matchingDiscountRuleFO = discountedFlightFOItem.matchingDiscountRule || null;
              }
            }
            return { ...d, ...prices, originalPrice, translations, matchingDiscountRuleFO };
          });
          this.extraFlights.push(...flightsFO);
        }
      });
      this.extraFlights = this.flightSort(this.extraFlights);
      this.extraFlights.forEach((flight) => {
        this.insertingAirlineInfo(flight);
        flight.cc = this.currencySymbol;
      });
    },
    insertingAirlineInfo(flight) {
      const pack = this.packages[0];
      // flight.airlineInfo = null;
      if (pack.airlineInfo) {
        let barCode = flight.FlightDetail[0].FL_AIRLINE;
        if (pack.airlineInfo && pack.airlineInfo[barCode]) flight.FlightDetail[0].airlineInfo = pack.airlineInfo[barCode];

        barCode = flight.FlightDetail[1].FL_AIRLINE;
        if (pack.airlineInfo && pack.airlineInfo[barCode]) flight.FlightDetail[1].airlineInfo = pack.airlineInfo[barCode];
      }
    },
    getPriceInfo(pPricesAge) {
      if (!pPricesAge) return null;
      const minPrice = pPricesAge.find(
        (d) => d.age_from === 0 && d.age_to === 0,
      ).price;

      let totalPrice = 0;
      if (Number(this.query.adult) > 0) {
        totalPrice
          += Number(this.query.adult)
          * pPricesAge.find((d) => d.age_from === 0 && d.age_to === 0).price;
      }
      if (Number(this.query.child > 0)) {
        totalPrice
          += Number(this.query.child)
          * pPricesAge.find((d) => d.age_from === 2 && d.age_to === 12).price;
      }
      if (Number(this.query.infant) > 0) {
        totalPrice
          += Number(this.query.infant)
          * pPricesAge.find((d) => d.age_from === 0 && d.age_to === 2).price;
      }
      return { minPrice, totalPrice };
    },
    sortArray([...arr], sortItem, order = 1) {
      arr.sort((a, b) => {
        if (Number(a[sortItem]) > Number(b[sortItem])) {
          return order;
        } else if (Number(a[sortItem]) < Number(b[sortItem])) {
          return -1 * order;
        }
        return 0;
      });
      return arr;
    },
    flightSort(arr) {
      const showHotIconFlights = arr.filter((item) => item.matchingDiscountRuleFO && (item.matchingDiscountRuleFO?.roundtripFlightsCode !== null || item.matchingDiscountRuleFO?.ruleType === 2));
      const otherFlights = arr.filter((item) => !item.matchingDiscountRuleFO || (item.matchingDiscountRuleFO?.roundtripFlightsCode === null && item.matchingDiscountRuleFO?.ruleType !== 2));
      return [
        ...this.sortArray(showHotIconFlights, 'minPrice'),
        ...this.sortArray(otherFlights, 'minPrice'),
      ];
    },
    changeFlightIndex(index) {
      this.idxFlight = index;
      const activeFlightId = `${this.extraFlights[index].FlightDetail[0]?.FL_ID}${this.extraFlights[index].FlightDetail[1]?.FL_ID}`;

      this.$store.commit('SET_FLIGHT_ID', activeFlightId);

      this.product.flights = [JSON.parse(JSON.stringify(this.extraFlights[index]))];
      this.$store.commit('SET_PRODUCT', this.product);

      this.setFlightOfProduct();

      setTimeout(() => {
        this.visibleMoreFlights = false;
        if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, this.scrollTopFlight);
      }, 2000);
    },
  },
};
