const exportDoc = {
  methods: {
    async exportWord() {
      // const result = await this.$store.dispatch('EXPORT_HTML_TO_WORD', { html: this.$refs.exportDoc });
      this.exportHTML2Word(this.$refs.exportDoc.innerHTML);
    },
    async sendingDoc() {
      // const result = await this.$store.dispatch('EXPORT_HTML_TO_WORD', { html: this.$refs.exportDoc });
      this.exportHTML2WordAndSend(this.$refs.sendDoc.innerHTML);
    },
    async exportPDF() {
      // const result = await this.$store.dispatch('EXPORT_HTML_TO_PDF', { html: this.$refs.exportDoc.innerHTML });
      const result = await this.$store.dispatch('EXPORT_HTML_TO_PDF', { html: '<div>test</div>' });
      // console.log(result);
      this.downloadTickets(result.data, 'test.pdf');
    },
    exportHTML2Word(html) {
      const source = this.makeDocFile(html);
      const fileDownload = document.createElement('a');
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = 'document.doc';
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
    async exportHTML2WordAndSend(html) {
      const source = new Blob([html], { type: 'text/html' });
      const response = await this.$store.dispatch('SEND_PRICE_PLAN_FILE_TO_WHATSAPP', { exportFile: source });
      if (response) {
        this.$bvModal.show('confirm-modal');
      }
    },
    makeDocFile(html) {
      const header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "
            + "xmlns:w='urn:schemas-microsoft-com:office:word' "
            + "xmlns='http://www.w3.org/TR/REC-html40'>"
            + "<head><meta charset='utf-8'><title>Export HTML to Word Document</title></head><body dir='rtl'>";
      const footer = '</body></html>';
      const sourceHTML = header + html + footer;

      return `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(sourceHTML)}`;
    },
    downloadTickets(pURL, pName) {
      const fileName = pName;
      const blob = new Blob([pURL], { type: 'application/octetstream' });
      const url = window.URL || window.webkitURL;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = url.createObjectURL(blob);
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
        } else {
          const evt = new MouseEvent('click');
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
  },
};

export default exportDoc;
