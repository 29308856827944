<template>
  <div>
    <Breadcrumb type="list-area" :listLinkData="linkData"/>
    <Banner :banner="bannerImage" />
    <div class="my-5 hotelplusflight">
      <div class="container">

          <div class="col-md-12 filter-cont dVacation-pack">
            <div class="holiday-hotel-details">
              <h2>{{ packName }}</h2>
            </div>
            <div class="clearfix"></div>
            <Breadcrumb type="list-flat" :listLabelData="listLabel"/>
            <CardGroup :items="destinations"/>
          </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bannerImageDestination from '@/utils/bannerImageDestination';

export default {
  name: 'Destination-List',
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
    CardGroup: () => import('@/components/atoms/CardColumnGroup'),
  },
  mixins: [bannerImageDestination],
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      categories: 'GET_CATEGORIES',
      listDestinations: 'GET_LANDING_DESTINATIONS',
      destinationImages: 'GET_DESTINATION_IMAGES',
    }),
    packCategory() {
      return this.query.packCategory;
    },
    packName() {
      return this.categories.find((d) => (d.code === this.packCategory)).name[this.lang];
    },
    linkData() {
      return [
        {
          link: '/',
          text: this.$t('product-page.main'),
        },
        {
          link: '/מדריך_היעדים',
          text: 'מדריך_היעדים',
        },
        {
          link: '#',
          text: this.packName,
        },
      ];
    },
    listLabel() {
      return {
        stage: 1, // The first number is 0
        textList: [
          {
            text: 'choose deal type',
          },
          {
            text: 'choose destination',
          },
          {
            text: 'choose hotel',
          },
        ],
      };
    },
    destinations() {
      const { listDestinations, packCategory, destinationImages } = this;
      if (!listDestinations || !destinationImages.length) return [];

      return listDestinations[packCategory].map((element) => {
        const item = {};
        item.images = destinationImages?.find((d) => (d.code === element.code))?.imageUrls;
        item.link = `/hotel-list/?packCategory=${packCategory}&dest=${element.code}`;
        item.name = element.name[this.lang];
        item.subName = element.countryName[this.lang];
        return item;
      });
    },
  },
  data() {
    return {
      query: '',
    };
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'destination-list');
    this.query = this.$route.query;
    await this.$store.dispatch('FETCH_DESTINATION_IMAGES');
  },
  methods: {

  },
};
</script>
