<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppDealPage',
  components: {},
  data() {
    return {
      lang: 'he',
    };
  },
  computed: {
    ...mapGetters({
      isDesktopApp: 'GET_BONAUF_DESKTOP_APP',
    }),
  },
  mounted() {
    const accessibility = document.querySelector('.accessibility_component > a.btn_accessibility.accessibility_component');
    if (accessibility && !this.isDesktopApp) accessibility.style.display = 'none';
  },
};
</script>

<style>
  .clearfix:before, .clearfix:after {
      content: " ";
      display: table;
  }
  .clearfix:after {
      clear: both;
  }

  .clearfix {
    *zoom: 1;
  }

  .tabs-container{direction:rtl;}

  .tabs-pad{padding:15px 4.6875%;}

  .tabs-container-title{
    margin:0 0 5px 0;
    font-size:15px;
  }

  .tabs-container img{vertical-align:middle;}

  #pack_img{margin:0 0 12px 0;}

  #pack_img img{width:100%;}

  .tabs-container p{margin:0 0 20px 0;}

  .tabs-container a{color:#2486c2;}

  a.tabs-container-more{
    font-weight:700;
    color:#ba1600;
    text-decoration:none;
  }

  .tabs-container-tour{margin:0 0 20px 0;}

  .tabs_blue_content_day .font_13 {font-size: 11px;}

  .tabs_blue_content_text{float:right;width:84%;}

  .tabs-container-flight{border-bottom:solid 2px #c7c7c7;}

  .tabs-container-flight:last-child {border:none; }

  .tabs-container-flight.noborder{border-bottom:0;}

  .tabs-container-flight-departure, .tabs-container-flight-arrival{padding:10px  4.6875%;}
  .tabs_blue_content_title{
    margin: 0 0 5px;
    font-size: 12px;
    font-weight:700;
  }

  .tabs-container-airplane{
    float:right;
    margin-left:10px;
  }

  .tabs-container-seat{
    float:left;
    margin-top:2px;
    font-size:10px;
  }

  .tabs-container-seat.no-seat{color:#ba1600;}

  .tabs-container-flight-content{float:right;}

  .pack_inner_table{width:100%;}

  .pack_inner_table th{border-bottom:solid 2px #e5e5e5;padding:0 0 9px;font-weight:700;color:#ba1600;text-align:right;}
  .pack_inner_table th:first-child{width:65%;padding-left:3%;}
  .pack_inner_table th:nth-child(2){width:21%;}
  .pack_inner_table th:last-child{width:11%;}

  .pack_inner_table td{border-bottom:solid 2px #e5e5e5;padding: 8px 0;font-size:11px;;}
  .pack_inner_table td:first-child{padding-left:3%;font-size:12px;}
  .pack_inner_table td:last-child{text-align:left;}

  .tabs-container-car{
    float:right;
    width:98px;
    margin-left:15px;
    border:solid 1px #cdcdcd;
  }

  .tabs-container-car-content{
    float:right;
    width:60%;
    font-size:11px;
  }

  .tabs-container-car-content p{margin:6px 0;}

  .pack_inner_price_comments{
    margin:7px 0 0 0;
    padding:0;
    list-style:none;
  }

  .pack_inner_price_comments li strong{color:#777777;}

  .box_flight_noseats{
    background-position:right bottom;
    color:#ba1600;
  }

  .starsWrap{
    margin-top: 2px;
    margin-bottom:1rem;
  }

</style>
