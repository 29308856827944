st<template>
  <div class="wrapper-card">
    <div class="price">
      <span class="icon"><img :src="`${imageDomain}/assets/img/after-booking-icon1.png`" alt="after-booking" title="" width="20" height="20"/></span>
      {{ $t("booking.total-to-pay-non-refundable") }} : <strong>{{ price }}</strong>
    </div>
    <div class="border border-light m-auto message-to-visitor p-1 shadow-sm text-center w-50" v-if="messageToVisitor">
     <div>{{ messageToVisitor }}</div>
    </div>
    <p class="text-center w-100 mt-3 mb-0" style="color:rgb(226, 9, 9);"><strong>{{ $t("booking.cant-debit-american-diner") }}</strong></p>

    <iframe id="verifyingCard"
      title="Card Verifiying"
      :src="path1"
      >
    </iframe>

    <b-modal
      id="statusPopup"
      centered
      size="lg"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      :title="$t('booking.notice')"
    >
      <div :class="[lang == 'he' ? 'text-right' : 'text-left']" :dir="lang==='he' ? 'rtl' : 'ltr'" v-html="statusMessage"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import googleAnalytics from '@/utils/googleAnalytics';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [googleAnalytics, imageUrlMixin, gMixin],
  computed: {
    ...mapGetters({
      path: 'GET_VERIFYING_URL',
      lang: 'GET_LANGUAGE',
      productState: 'GET_PRODUCT',
      isMobileApp: 'GET_STATE_MOBILE_APP',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      maccabiUrlPrices: 'GET_MACCABI_VERIFYING_URLS',
    }),
    bookingInfo() {
      return JSON.parse(window.sessionStorage.getItem('BookingDetailInfo')).data;
    },
    price() {
      const price = (this.isMaccabiAgency) ? this.maccabiCurrentPrice : this.bookingInfo.priceNIS;
      return `₪${price}`;
    },
    storageVerifyingUrl() {
      return window.sessionStorage.getItem('VERIFYING_URL');
    },
    storageBookingDataID() {
      return window.sessionStorage.getItem('BookingDataID');
    },
    storageMarketerURL() {
      return window.sessionStorage.getItem('marketerURL');
    },
    maccabiCurrentIndex() {
      const { maccabiUrlPrices } = this;
      return maccabiUrlPrices.currentIndex || 0;
    },
    maccabiCurrentUrl() {
      const { maccabiUrlPrices, maccabiCurrentIndex } = this;
      return maccabiUrlPrices.urls[maccabiCurrentIndex];
    },
    maccabiCurrentPrice() {
      const { maccabiUrlPrices, maccabiCurrentIndex } = this;
      return maccabiUrlPrices.prices[maccabiCurrentIndex];
    },
  },
  data() {
    return {
      path1: '', // "https://www.credit2000.co.il/web_2202/redirect/peleg/credit2000he.aspx?params=400f191a-b140-4514-a4ca-22ab34d2d1e7&css=assets/css/styleVerify.css",
      product: null,
      category: '',
      messageToVisitor: window.sessionStorage.getItem('messageToVisitor') || '',
      hotelStatus: false,
      statusMessage: this.$t('booking.status-change-advise'),
    };
  },
  created() {
    const localProduction = JSON.parse(
      window.sessionStorage.getItem('production'),
    );
    this.category = this.categoryState || localProduction.category;
    this.product = this.productState || localProduction;

    this.path1 = (this.isMaccabiAgency) ? this.maccabiCurrentUrl : this.path || this.storageVerifyingUrl;

    window.sessionStorage.setItem('VERIFYING_URL', this.path1);

    if (!this.path1.includes('http')) {
      this.sendMailError('*********** VERIFYING URL ***********',
        `path: ${this.path}
        localStoragePath: ${this.storageVerifyingUrl}
        price: ${this.price}`);
    }
  },
  async mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);

    if (this.availableEmitEventGA4ByDomain()) {
      let name = '', checkInOut = '', destName = '';
      const currencyCode = this.convertCurrencyCode(this.product.cc);
      const affiliation = this.isMobileApp ? 'BO NAUF' : 'Flying Carpet';
      if (this.category.code === 'Flight_Only') {
        const flight = this.product.flights[0].FlightDetail[0],
          destCode = flight?.FL_To_Route;
        destName = flight?.translations?.flightDestinationName?.[destCode].he || flight?.translations?.flightDestinationName?.[destCode].en;
        name = `${flight.arl_Name} - ${destName}`;
        checkInOut = `${this.product.flights[0].FlightDetail[0].FL_Date} - ${this.product.flights[0].FlightDetail[0].landingDate}`;
      } else {
        const { hotelName } = this.product.hotels[0];
        destName = this.product?.translations?.flightDestinationName?.[this.product?.destination_1]?.he || this.product?.translations?.flightDestinationName?.[this.product?.destination_1]?.en;
        name = `${hotelName} - ${destName}`;
        checkInOut = `${this.bookingInfo?.outwardFlight?.departureDate} - ${this.bookingInfo?.inwardFlight?.departureDate}`;
      }
      this.gtag('event', 'begin_checkout', {
        currency: currencyCode,
        value: this.bookingInfo.price,
        items: [
          {
            item_id: this.storageBookingDataID,
            item_name: name,
            affiliation,
            coupon: '',
            discount: '',
            index: 0,
            item_brand: '',
            item_category: destName,
            item_category2: checkInOut,
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_list_id: '',
            item_list_name: '',
            item_variant: '',
            location_id: '',
            price: this.bookingInfo.price,
            quantity: 1,
          },
        ],
      });
    }

    this.hotelStatus = await this.$store.dispatch('CHECK_ROOM_STATUS_IN_CARD_PAGE');
    this.checkStatusChecking();
  },
  methods: {
    async sendMailError(pStage, pResponse) {
      const body = `<h3>${pStage}</h3></Br>
        <div><p>${this.storageBookingDataID}</p></div></Br>
        <div><p>${this.storageMarketerURL}</p></div></Br>
        <div><pre>${pResponse}</pre></div>
        `;
      const notification = {
        subject: `${pStage}`,
        body,
      };
      await this.$store.dispatch('NOTIFY_ADMIN', notification);
    },
    checkStatusChecking() {
      const oldStatus = this.product.hotel.some((room) => room.status);
      if (!this.hotelStatus && oldStatus) {
        this.$bvModal.show('statusPopup');
      }
    },
  },
};
</script>

<style scoped>
  #verifyingCard{
    width: 100%;
    height: 670px;
    border: none;
  }
  .wrapper-card{
    margin : auto;
    width: 100%;
    height: 100%;
  }
  .price{
    font-size : 30px;
  }
  .message-to-visitor{
    border-radius: 10px;
  }

</style>

<style>
  #tp_card .tp_input.sc_row {
    width: 100%;
  }
</style>
