<template>
  <div>
    <Breadcrumb type="area" :data="linkData" v-if="linkData" />
    <Banner :banner="bannerImage" />
    <div class="my-5 hotelplusflight">
      <div class="container">
        <div class="row">
          <Sidebar
            :data="sidebarData"
            :packageType="categoryCode"
            :remarks="remarks"
          />
          <div
            class="col-md-9 filter-cont dVacation-pack mr-auto"
            v-if="isLoading || hotels.length === 0"
          >
            <ContentLoading type="product" />
          </div>
          <div class="col-md-9 filter-cont dVacation-pack" v-else>
            <div class="holiday-hotel-details">
              <h2>
                {{ categoryName }} {{ $t('product-page.for') }}
                {{ destinationName }} - {{ hotelName }}
              </h2>
            </div>
            <div class="clearfix"></div>
            <Breadcrumb type="flat" :stage="bookingStage" />
            <ReadMore :message="description" textAlign="text-left" />
            <div class="facilitie-list" dir="ltr">
              <Facilitie
                v-for="(item, index) in facilities"
                :key="index"
                :data="item"
                direction="left"
              />
            </div>
            <div class="clearfix"></div>
            <Slider :slides="imageList" v-if="this.imageList.length" />

            <div class="hotelplusflight_bannerarea text-left" dir="ltr">
              <div class="hotelplusflight_body">
                <h2>{{ hotelName }}</h2>
                <div class="d-flex retingbox">
                  <b-form-rating
                    class="ui rating"
                    v-model="rating"
                    variant="warning"
                    readonly
                  ></b-form-rating>
                </div>
                <span class="d-inline-block"><a href="#"><i class="fas fa-map-marker-alt"></i> {{ address }}</a></span>
                <p class="d-block dated">
                  <i class="far fa-calendar-alt ml-0"></i>
                  {{ betweenDateString }}
                </p>
                <p><i class="fas fa-user ml-0"></i> {{ personString }}</p>
              </div>
            </div>

            <CollapseList
              :data="product.activeHotel[0]"
              :categoryName="categoryCode"
            />

            <div class="chooseroom_area">
              <OrderRooms :hotel="activeHotel" dir="ltr" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="device === 'desktop'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormRating } from 'bootstrap-vue';
import dayjs from 'dayjs';
import bannerImageDestination from '@/utils/bannerImageDestination';

export default {
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    ReadMore: () => import('@/components/productPage/atoms/Readmore'),
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    CollapseList: () => import('@/components/productPage/CollapseList'),
    OrderRooms: () => import('@/components/productPage/OrderRooms'),
    Slider: () => import('@/components/productPage/ProductSlider'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
    BFormRating,
  },
  mixins: [bannerImageDestination],
  data() {
    return {
      query: '',
      linkData: null,
      bookingStage: 1,
      stageLabel: [
        '',
        'product-page.package-details',
        'product-page.payment-details',
        'product-page.Order-Completion',
      ],

      remarks: 'remarks string',

      product: null,
      hotels: [],
      categoryCode: '',
      sidebarData: [],
      activeHotel: null,

      categoryName: '',
      destinationName: '',
      hotelName: '',
      description: '',
      rating: 0,
      address: '',
      betweenDateString: '',
      personString: '',

      facilities: [],

      imageList: [],
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      isLoading: 'GET_LOADING_STATE',
      packages: 'GET_PACKAGES',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      facilitiesData: 'GET_FACILITIES_HOTEL_ONLY',
    }),
  },
  async created() {
    this.query = this.$route.query;
    window.sessionStorage.setItem('BookingDataID', '');
    const isBlankData = await this.fetchData();
    if (!isBlankData) return;

    this.updateLabelWithLang();
  },
  mounted() {},
  methods: {
    async fetchData() {
      if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
      const response = await this.$store.dispatch(
        'FETCH_PRODUCT_HOTEL_ONLY',
        this.query,
      );
      this.product = {
        activeHotel: response,
        category: this.category,
        hotels: [],
      };
      await this.$store.dispatch('FETCH_FACILITIES_HOTEL_ONLY');
      if (this.product === undefined) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
        return false;
      }

      this.categoryCode = this.category.code;
      this.updateLabelWithLang();

      this.hotels = this.product.activeHotel;

      const paxes = [];
      if (this.query.ages !== '') {
        const ageChildren = this.query.ages.split(',');
        ageChildren.forEach((age) => {
          paxes.push({
            type: 'CH',
            age: Number(age),
          });
        });
      }

      this.activeHotel = {
        categoryId: this.query.categoryId,
        stay: {
          checkIn: this.query.dateFrom,
          checkOut: this.query.dateTo,
        },
        occupancies: [
          {
            rooms: 1,
            adults: Number(this.query.adult),
            children: Number(this.query.child),
            paxes,
          },
        ],
        currency: this.query.currency,
      };
      const checkIn = dayjs(this.query.dateFrom),
        checkOut = dayjs(this.query.dateTo),
        duration = checkOut.diff(checkIn, 'day');

      this.sidebarData = [
        {
          address: this.hotels[0].address.content,
          cityName: this.hotels[0].city.content,
          hotelName: this.hotels[0].name.content,
          checkIn: this.query.dateFrom,
          checkOut: this.query.dateTo,
          grade: 0,
          duration,
        },
      ];

      this.product.hotels = this.sidebarData;
      this.$store.commit('SET_PRODUCT', this.product);

      this.categoryName = this.category?.name?.en;
      this.destinationName = this.hotels[0]?.city?.content;
      this.hotelName = this.hotels[0]?.name?.content;
      this.description = this.hotels[0]?.description?.content;
      this.rating = 0;
      this.address = this.hotels[0]?.address?.content;
      this.betweenDateString = this.getBetweenDateString(
        this.query.dateFrom,
        this.query.dateTo,
        'dddd DD/MM/YYYY',
        true,
      );
      this.personString = this.getPersonString(
        this.query.adult,
        this.query.child,
        this.query.infant,
      );

      this.fillFacilitiesData();

      const imgType = this.device === 'desktop' ? 'bigger/' : '';
      this.imageList = this.hotels[0].images.map(
        (img) => `http://photos.hotelbeds.com/giata/${imgType}${img.path}`,
      );

      await this.fetchBannerImageByDestination();
      return true;
    },
    updateLabelWithLang() {
      this.linkData = {
        text: this.category?.name[this.lang] || this.category?.name?.en,
        dest: 'hotel dest',
        stage: this.$t(this.stageLabel[this.bookingStage]),
      };
    },
    fillFacilitiesData() {
      const oneself = this;
      this.facilities = this.hotels[0].facilities
        .map((el) => {
          const matchFacility = oneself.facilitiesData.data.find(
            (item) => item.facilityGroupCode === el.facilityGroupCode
              && item.code === el.facilityCode,
          );
          return matchFacility?.description?.content || '';
        })
        .filter((el) => el !== '');
      this.hotels[0].facilitiesData = this.facilities;
    },
    getBetweenDateString(pFrom, pTo, pFormat, pAddDuration) {
      const checkIn = dayjs(pFrom),
        checkOut = dayjs(pTo);
      let dateString = `${checkIn.format(pFormat)} - ${checkOut.format(
        pFormat,
      )}`;

      if (pAddDuration) {
        dateString = `${dateString} (${checkIn.diff(checkOut, 'day')} nights)`;
      }
      return dateString;
    },
    getPersonString(adult, child, infant) {
      let str = ` ${this.$t('product-page.adult')}: ${adult}`;
      if (child > 0) str += `, ${this.$t('product-page.child')}: ${child}`;
      if (infant > 0) str += `, ${this.$t('product-page.infant')}; ${infant}`;
      return str;
    },
  },
};
</script>

<style>
.modal {
  background-color: transparent;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.1em;
}
</style>

<style scoped>
.hotelplusflight {
  margin-top: 1em !important;
}
.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}
@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 3px;
  }
}
</style>
