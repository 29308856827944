<template>
  <div>
    <Breadcrumb type="list-area" :listLinkData="linkData"/>
    <Banner :banner="bannerImage" />
    <div class="my-5 hotelplusflight">
      <div class="container">

          <div class="col-md-12 filter-cont dVacation-pack">
            <div class="holiday-hotel-details">
              <h2>{{ packName }}</h2>
              <h3>{{ destNameCountry.dest }} {{ destNameCountry.country }}</h3>
            </div>
            <div class="clearfix"></div>
            <Breadcrumb type="list-flat" :listLabelData="listLabel"/>
            <CardGroup :items="hotels"/>
          </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bannerImageDestination from '@/utils/bannerImageDestination';

export default {
  name: 'Destination-List',
  components: {
    Banner: () => import('@/components/productPage/ProductBanner'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
    CardGroup: () => import('@/components/atoms/CardColumnGroup'),
  },
  mixins: [bannerImageDestination],
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      categories: 'GET_CATEGORIES',
      listHotels: 'GET_HOTEL_LIST_BY_DESTINATION',
      listDestinations: 'GET_LANDING_DESTINATIONS',
      destinationImages: 'GET_DESTINATION_IMAGES',
    }),
    packCategory() {
      return this.query.packCategory;
    },
    packName() {
      return this.categories.find((d) => (d.code === this.packCategory)).name[this.lang];
    },
    destCode() {
      return this.query.dest || '';
    },
    destNameCountry() {
      if (!this.listDestinations) return { dest: '', country: '' };
      const item = this.listDestinations[this.packCategory].find((d) => (d.code === this.destCode));
      return { dest: item.name[this.lang], country: item.countryName[this.lang] };
    },
    linkData() {
      return [
        {
          link: '/',
          text: this.$t('product-page.main'),
        },
        {
          link: '/מדריך_היעדים',
          text: 'מדריך_היעדים',
        },
        {
          link: `/destination-list?packCategory=${this.packCategory}`,
          text: this.packName,
        },
        {
          link: '#',
          text: this.destNameCountry?.dest,
        },
      ];
    },
    listLabel() {
      return {
        stage: 2, // The first number is 0
        textList: [
          {
            text: 'choose deal type',
          },
          {
            text: 'choose destination',
          },
          {
            text: 'choose hotel',
          },
        ],
      };
    },
    hotels() {
      const { listHotels, destinationImages, destCode } = this;
      if (!listHotels.length || !destinationImages.length) return [];

      return listHotels.filter((element) => element.cityCode === destCode).map((element) => {
        const item = {};
        item.images = destinationImages?.find((d) => (d.code === destCode))?.imageUrls;
        item.link = '#';
        item.name = element.name;
        item.subName = element.name_Loc.content;
        return item;
      });
    },
  },
  data() {
    return {
      query: '',
    };
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'hotel-list');
    this.query = this.$route.query;
    await this.$store.dispatch('FETCH_HOTEL_LIST_BY_DEST');
    await this.$store.dispatch('FETCH_DESTINATION_IMAGES');
    await this.fetchBannerImageByDestination();
  },
  methods: {

  },
};
</script>
