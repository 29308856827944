<template>
  <div>
    <Breadcrumb type="search-area" :data="linkData" :categoryName="categoryName" v-if="linkData" />
    <Banner :banner="bannerImage" type="search-result" :image-caption="imageCaption"/>
    <InfoStrip :count="searchResultCount" :categoryName="categoryName" :loading="loading"/>

    <!-- <QuickSearchSlide
      v-if="quickSearches.length > 0"
      :searches="quickSearches"
      @quickSearch="quickSearch"
    /> -->

    <MobileFilter
      :category="category"
      v-if="category"
      :sortButtonList="mobileSortButtonList"
      @updateSort="doSort"
      @filterChange="filterByOption"
      :filterOptions="filterOptions"
    />

    <div v-if="category" class="my-5" :class="categoryName === 'Flight_Only' ? 'flightsonlypage' : 'sportspage_area'" ref="calc-offset">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12 sideBar desktop_form">
            <!-- <SearchTab
              v-if="category.code === 'vacation_pack'"
              :category="category"
              type="desktop"
            /> -->
            <!-- <SearchTabOT
              v-if="category.code === 'Organize_tour_packages'"
              :category="category"
              type="desktop"
            />
            <SearchTabFOD
              v-if="category.dealTypeCode === 'Flight_Only'"
              :category="category"
              type="desktop"
              @updatedDestination="updatedDestination"
            /> -->
            <search-tabs type="side"/>
            <filter-panel
              v-if="!isLoading && categoryName !== 'Flight_Only'"
              :options="filterOptions"
              @change="filterByOption"
            />
          </div>
          <div v-if="categoryName !== 'Flight_Only' && loading" class="col-lg-9 col-12 filter-cont package-organize">
            <ContentLoading />
          </div>
          <div v-else-if="categoryName === 'Organize_tour_packages'" class="col-lg-9 col-12 filter-cont package-organize">
              <div class="desktop_form" :class="isScroll && 'sticky'">
                <h3>
                  {{ $t('search-result.goa') }}: {{ countFilteredItems }}
                  <span>{{ $t('search-result.properties-found-organize') }}{{ destinationString }}</span>
                </h3>
                <!-- <h4>{{ tripDuration }}</h4> -->
                <hr v-if="!isScroll"/>
                <SortButtons :buttonList="sortButtonList" @updateSort="doSort" :isFixed="isScroll"/>
              </div>
              <!-- <div v-if="category.dealTypeCode === 'vacation_pack'"> -->
              <FilterItem v-for="(item, inx) in filteredHotel"
                :key="inx"
                :hotel="item"
                :itemIndex="inx"
                @showGalleryShare="openGalleryShareModal"
                :categoryName="categoryName" />
                <!-- <scroll-loader :loader-method="fillThumbImageToHotel" v-if="completedFilteredHotelOnly.length < filteredHotel.length"></scroll-loader>
              </div> -->

              <!-- <div v-if="category.dealTypeCode === 'Hotel_Only'">
                  <HotelItem v-for="(item, idx) in completedFilteredHotelOnly"
                              :key="idx"
                              :hotel="item"
                              :itemIndex="idx" />
                  <scroll-loader :loader-method="fillThumbImageToHotel" v-if="completedFilteredHotelOnly.length < filteredHotelOnly.length"></scroll-loader>
              </div> -->
          </div>
          <div v-else-if="categoryName !== 'Flight_Only'" class="col-lg-9 col-12 filter-cont package-organize">
            <div class="desktop_form" :class="isScroll && 'sticky'">
              <h3 >
                {{ $t('search-result.goa') }}: {{ countFilteredItems }}
                {{ $t('search-result.properties-found') }}<span>{{ destinationString }}</span>
              </h3>
              <h4>{{ tripDuration }}</h4>
              <hr v-if="!isScroll"/>
              <SortButtons :buttonList="sortButtonList" @updateSort="doSort" :isFixed="isScroll"/>
            </div>

            <FilterItem
              v-for="(item, inx) in filteredHotel"
              :key="inx"
              :hotel="item"
              :itemIndex="inx"
              @showGalleryShare="openGalleryShareModal"
              :categoryName="categoryName"
            />
          </div>

          <div v-else-if="categoryName === 'Flight_Only'" class="col-lg-9 col-12 filter-cont dVacation-pack">

            <div class="card card-body border-0 bg-theme mb-4">
              <p>
                <PrevNextDateFlight/>
              </p>
            </div>
            <div v-if="loading">
              <ContentLoading />
            </div>
            <div>
              <div class="py-3">
                <h3>
                  {{ $t('search-result.goa') }}: {{ flightOnlyItems.length }}
                  {{ $t('search-result.flights') }}<span>{{ destinationString }}</span>
                </h3>
              </div>

              <FlightOnlyItem
                v-for="(item, inx) in flightOnlyItems"
                :key="inx"
                :flight="item"
                :categoryName="categoryName"
              />

              <!-- <div class="card card-body border-0 bg-theme mt-4 mb-4">
                <p>{{ $t("search-result.flight-only-desc") }}</p>
                <p>
                  {{ packages[0] && packages[0].translations.flightDestinationName[packages[0].destination_1][lang] }},
                  {{ packages[0] && packages[0].translations.flightDestinationCountry[packages[0].destination_1][lang] }}
                </p>
              </div> -->

            </div>
          </div>
          <div v-else-if="categoryName === 'Hotel_Only'">
            <HotelItem
              v-for="(item, idx) in filteredHotelOnly"
              :key="idx"
              :hotel="item"
              :itemIndex="idx"
            />
            <scroll-loader :loader-method="fillThumbImageToHotel" v-if="completedFilteredHotelOnly.length < filteredHotelOnly.length"></scroll-loader>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="gallery-modal" v-model="gallerySliderModal" size="lg">
      <template #default>
        <Slider v-if="galleryImageList.length" :slides="galleryImageList" :destinationImage="destinationImage" :hotelName="shareHotel"/>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <router-link :to="galleryModalBookLink">
          <b-button size="lg" variant="success" @click="ok()">{{$t('product-page.order')}}</b-button>
        </router-link>
        <b-button size="lg" variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="share-this-modal" v-model="shareThisModal">
      <template #modal-header>
        <h4> {{ $t("share-this.share-modal-title") }} </h4>
      </template>
      <template #default>
        <ShareThis :shareContent="shareData"/>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()">
          {{$t('booking.close')}}
        </b-button>
      </template>
    </b-modal>

  <Footer v-if="(device !== 'mobile' || !loading) && !footerHide"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { BModal, BButton } from 'bootstrap-vue';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'SearchResult',
  components: {
    BButton,
    Banner: () => import('@/components/productPage/ProductBanner'),
    InfoStrip: () => import('@/components/searchResult/InforStrip'),
    FilterPanel: () => import('@/components/searchResult/atoms/FilterPanel'),
    MobileFilter: () => import('@/components/searchResult/MobileFilter'),
    // SearchPopup: () => import('@/components/searchResult/SearchPopup'),
    // SearchTab: () => import('@/components/search/SearchTab'),
    // SearchTabOT: () => import('@/components/search/SearchTabOT'),
    // SearchTabHO: () => import('@/components/search/SearchTabHO'),
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    FilterItem: () => import('@/components/searchResult/atoms/FilterItem'),
    HotelItem: () => import('@/components/searchResult/atoms/HotelItem'),
    SortButtons: () => import('@/components/searchResult/atoms/SortButtons'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
    // Footer: () => import('@/components/HeaderFooter/Footer'),
    Footer: () => import('@/components/HeaderFooter/footer/FooterTheme0'),
    PrevNextDateFlight: () => import('@/components/searchResult/atoms/PrevNextDateFlight'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    Slider: () => import('@/components/productPage/ProductSlider'),
    BModal,
    ShareThis: () => import('@/components/atoms/ShareThis'),
  },
  mixins: [bannerImageDestination, imageUrlMixin],
  props: {
    msg: String,
  },
  data() {
    return {
      showHotelCount: 5,
      isFiltered: false,
      // quickSearches: [],
      currencySymbol: '',
      packageFlights: [],
      hotels: [],
      filteredHotel: [],

      filteredFlightHotel: [],

      filteredHotelOnly: [],
      completedFilteredHotelOnly: [],

      flightOnlyItems: [],

      query: this.$route.query,
      loading: false,

      lastSort: [],
      sortButtonList: [],
      mobileSortButtonList: [],
      priceFilterItem: null,
      filterPriceValues: [],
      categoryName: '',

      searchResultCount: 0,
      destinationString: '',

      filterOptions: {},
      isScroll: false,
      gallerySliderModal: false,
      crtGalleryInx: 0,
      galleryModalBookLink: '',
      // testFilterOption: {
      //   title: 'test filter',
      //   subItems: [
      //     { label: 'value 1', price: 50 },
      //   ],
      // },
      shareThisModal: false,
      shareIndex: 0,
      shareLink: '',
      shareHotel: '',
      shareImage: '',
      shareRate: '',
    };
  },
  metaInfo() {
    return {
      meta: [
        { property: 'og:title', content: `חופשות ב ${this.destinationString}` },
      ],
    };
  },
  computed: {
    ...mapGetters({
      devMode: 'GET_MODE',
      category: 'GET_CURRENT_CATEGORY',
      subjectCategory: 'GET_SUBJECTS_CATEGORY',
      storedSearchQuery: 'GET_SEARCH_QUERY',
      packages: 'GET_PACKAGES',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      dealItinerary: 'GET_DEAL_ITINERARY',
      destinationImages: 'GET_DESTINATION_IMAGES',
      marketerId: 'GET_MARKETER_ID',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      availSubjects: 'GET_SUBJECTS',
      enableSearchAgentState: 'GET_ENABLE_SEARCH_AGENT_STATE',
    }),
    regularOdyAgencyCode() {
      return this.whiteLabel?.odyAgentCode;
    },
    marketerOdyAgencyCode() {
      return this.marketerAgencyContent?.odyAgentCode;
    },
    destinationImage() {
      const { destinationImages } = this;
      if (!destinationImages || (destinationImages && !destinationImages.length)) return '';

      let dest = decodeURIComponent(this.query.dest);
      if (dest.includes(',')) dest = dest.split(',')[0];
      const matchedItem = destinationImages.find((d) => d.code === dest);
      return (matchedItem) ? destinationImages.find((d) => d.code === dest).galleryUrls[0] : '';
    },
    tripDuration() {
      const { packages, lang } = this;
      if (!packages || (packages && !packages.length)) return '';

      dayjs.locale(lang);
      const checkIn = dayjs(packages[0].flights[0]?.FlightDetail[0]?.FL_Date),
        // checkOut = dayjs(packages[0].flights[0]?.FlightDetail[1]?.FL_Date);
        checkOut = checkIn.add(Number(packages[0].duration_1), 'day');
      return `${checkIn.format('DD/MM/YY dddd')} - ${checkOut.format('DD/MM/YY dddd')}`;
    },
    countFilteredItems() {
      if (this.category.dealTypeCode !== 'Hotel_Only') {
        return this.filteredHotel ? this.filteredHotel.length : 0;
      } else if (this.category.dealTypeCode === 'Hotel_Only') {
        return this.filteredHotelOnly ? this.filteredHotelOnly.length : 0;
      } else {
        return 0;
      }
    },
    starRateFilterOption() {
      const { hotels } = this;
      if (!hotels || !hotels.length) return null;
      return {
        kind: 'star-rate',
        title: 'star-rate',
        subItems: [
          { label: 3 },
          { label: 4 },
          { label: 5 },
        ],
      };
    },
    hotelNameFilterOption() {
      const { hotels } = this;
      if (!hotels || !hotels.length) return null;
      const noneDupHotelNames = [...new Set(hotels.map((item) => (item.hotelName)).sort((a, b) => (a < b ? -1 : 1)))];
      const hotelNames = [...noneDupHotelNames.map((item) => ({ label: item }))];
      return {
        kind: 'hotel-name',
        title: 'hotelName',
        subItems: hotelNames,
      };
    },
    hotelBasisFilterOption() {
      const { hotels, packages, lang, categoryName } = this;
      if (!hotels || !hotels.length) return null;
      const noneDupBasisNames = [...new Set(hotels.map((item) => (item.basisName)))];
      let hotelBasis = [];
      if (categoryName === 'Organize_tour_packages') {
        hotelBasis = [...noneDupBasisNames.map((item) => {
          const translation = hotels.find((d) => d.basisName === item).translations;
          return { label: item, text: `${translation?.basisName?.[lang] ?? translation?.basisName?.en ?? item}` };
        })];
      } else {
        hotelBasis = [...noneDupBasisNames.map((item) => ({ label: item, text: `${packages[0]?.translations?.basisName?.[item]?.[lang] ?? packages[0]?.translations?.basisName?.[item]?.en ?? item}` }))];
        const sortField = packages[0].basisSortInfo;
        hotelBasis.sort((a, b) => sortField[b.label] - sortField[a.label]);
      }
      return {
        kind: 'hotel-basis',
        title: 'hotelBasis',
        subItems: hotelBasis,
      };
    },
    hotelSubjectFilterOption() {
      const { subjectCategory, lang, hotels } = this;
      if (!subjectCategory) return null;
      const dynamicSubjectList = subjectCategory.filter((item) => (hotels.findIndex((hotel) => (item.subjectCodes.indexOf(hotel.subject) > -1)) > -1));
      const subItems = dynamicSubjectList.map((item) => ({ label: (lang === 'he') ? item.nameHe : item.nameEn, value: item.id }));
      return {
        kind: 'hotel-subject',
        title: 'hotelSubject',
        subItems,
      };
    },
    hotelCityFilterOption() {
      const { hotels, lang } = this;
      if (!hotels) return null;
      const subItems = [
        ...new Map(
          hotels.map((x) => [x.destCode, x]),
        ).values(),
      ].map((item) => ({
        label: `${item.translations.flightDestinationName[item.destCode][lang]} - ${item.translations.flightDestinationCountry[item.destCode][lang]}`,
        value: item.destCode,
      }));

      return {
        kind: 'hotel-city',
        title: 'hotelCity',
        subItems,
      };
    },
    travelDaysFilterOption() {
      const { hotels } = this;
      if (!hotels) return null;
      const subItems = [
        ...new Map(
          hotels.map((x) => [x.duration, x]),
        ).values(),
      ].map((item) => ({ label: `${item.duration} ${this.$t('home.nights')} / ${Number(item.duration) + 1} ${this.$t('home.days')}`, value: item.duration }));
      return {
        kind: 'travel-duration',
        title: 'travelDuration',
        subItems,
      };
    },
    linkData() {
      return {
        dest: this.destinationString,
        searchQuery: `/search-result?${new URLSearchParams(this.query).toString()}`,
      };
    },
    usableHeight() {
      const element = this.$refs['calc-offset'];
      return element ? element.offsetTop - 60 : 0;
    },
    galleryImageList() {
      const { crtGalleryInx, filteredHotel } = this;
      if (!filteredHotel) return [];
      const images = filteredHotel[crtGalleryInx].adminHotelImageUrl ? [filteredHotel[crtGalleryInx].adminHotelImageUrl] : [];
      images.push(...filteredHotel[crtGalleryInx].galleryUrls);
      images.push(...filteredHotel[crtGalleryInx].perspectiveUrls);
      if (images.length === 0) images.push(`${this.imageDomain}/assets/img/has_no_picture.jpg`);
      return [...new Set(images)];
    },
    hideCondition() {
      const { isAgencyLogin, bypassPaymentState, limitDealsState, enableSearchAgentState, query } = this;
      return (((!isAgencyLogin && bypassPaymentState) || (!enableSearchAgentState && limitDealsState)) && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
      // const { isAgencyLogin, bypassPaymentState, limitDealsState, query } = this;
      // return (((!isAgencyLogin && bypassPaymentState) || limitDealsState) && query.secret !== null && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    },
    footerHide() {
      const { isAgencyLogin, bypassPaymentState, limitDealsState, query } = this;
      return (((!isAgencyLogin && bypassPaymentState) || limitDealsState) && query.secret !== null && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    },
    cheapestForAgents() {
      // const { isOdysseySite, packages } = this;
      const { packages } = this;
      let cheapest = {};
      if (this.categoryName === 'Organize_tour_packages') {
        packages.forEach((d) => {
          d.hotels.forEach((dd) => {
            cheapest[dd.La_ID] = d.cheapestForAgents && d.cheapestForAgents[dd.La_ID] ? d.cheapestForAgents[dd.La_ID] : null;
          });
        });
      } else {
        // cheapest = isOdysseySite ? packages[0].cheapestForAgents : null;
        cheapest = packages[0].cheapestForAgents;
      }
      return cheapest;
    },
    shareData() {
      return {
        category: this.category.dealTypeCode,
        dest: this.destinationString,
        hotel: this.shareHotel,
        image: this.shareImage,
        duration: this.tripDuration,
        link: `${this.shareLink}`,
        country: this.packages[0] && this.packages[0].translations.flightDestinationCountry[this.packages[0].destination_1][this.lang],
        rate: this.shareRate,
      };
    },
  },
  watch: {
    category() {
      this.categoryName = this.category?.dealTypeCode;
      this.initSearchResult();
    },
    packages: 'getHotelsFlights',
    $route: 'fetchData',
    isLoading() {
      this.loading = this.isLoading;
    },
    filterPriceValues() {
      this.applyPriceRangeFilter();
    },
    odyAgentCode() {
      this.filteredHotel = [];
      this.hotels = [];
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'searchResult');
    window.sessionStorage.removeItem('roomList');
    if (this.availSubjects.length === 0) this.$store.dispatch('FETCH_SUBJECTS');
    await this.fetchData();
  },
  async mounted() {
    this.categoryName = this.category?.code;
    if (this.isOdysseySite) this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.query.odyAgentCode || this.agentContent.odyAgentCode);
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    initSearchResult() {
      // this.flightOnlyItems = [];
      // this.filteredHotel = [];
      // this.filteredHotelOnly = [];

      this.completedFilteredHotelOnly = [];

      const idx = this.categoryName === 'vacation_pack' ? 0 : 1;
      this.filteredHotel = this.filteredFlightHotel[idx];
      this.updatePriceRangeFilterData();
    },
    verificationSearchQuery() {
      const { query } = this.$route;
      const defaultChecking = !!query.dest && !!query.adult && !!query.categoryId && query.dest !== 'undefined';
      const vacationFoChecking = (query.categoryId === 'vacation_pack' || query.categoryId === 'Flight_Only') && !!query.fromDate && !!query.toDate && query.fromDate !== 'undefined' && query.toDate !== 'undefined';
      const orderPackChecking = (query.categoryId === 'Organize_tour_packages' || query.categoryId === 'sport_pack') && !!query.yearMonth;

      return defaultChecking && (vacationFoChecking || orderPackChecking);
    },
    async fetchData() {
      await this.fetchBannerImageByDestination();

      if (this.hideCondition) return;
      this.query = this.$route.query;

      if (!this.verificationSearchQuery()) {
        this.$bvModal.msgBoxOk(
          this.$t('search-result.dont-remove-query'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
        return;
      }

      this.completedFilteredHotelOnly = [];
      sessionStorage.setItem('search-query', JSON.stringify(this.query));

      if (this.subjectCategory.length === 0) await this.$store.dispatch('FETCH_SUBJECT_CATEGORY');
      if (this.query.marketerId) this.$store.commit('SET_MARKETER_ID', this.query.marketerId);
      if (this.query.categoryId !== 'Hotel_Only') {
        if (this.query.categoryId === 'vacation_pack') {
          this.sortButtonList = ['gradeLTH', 'gradeHTL', 'priceLTH', 'priceHTL', 'grade'];
          this.mobileSortButtonList = ['gradeLTH', 'gradeHTL', 'priceLTH', 'priceHTL', 'grade'];
        } else if (this.query.categoryId === 'Organize_tour_packages' || this.query.categoryId === 'sport_pack') {
          this.sortButtonList = ['priceLTH', 'priceHTL', 'daysHTL', 'daysLTH'];
          this.mobileSortButtonList = ['priceLTH', 'priceHTL', 'daysHTL', 'daysLTH'];
        }

        if (this.packages && this.packages.length
          && Object.keys(this.query).every((key) => String(this.storedSearchQuery[key]) === String(this.query[key]))
          && Object.keys(this.storedSearchQuery).every((key) => String(this.storedSearchQuery[key]) === String(this.query[key]))) {
          this.getHotelsFlights();
        } else {
          this.$store.commit('SET_SEARCH_QUERY', this.query);
          await this.$store.dispatch('FETCH_PACKAGE_BY_SEARCH', this.query);
        }
      } else if (this.packages && this.packages.length && this.storedSearchQuery === this.query) {
        this.getHotelsFlights();
      } else {
        // fetch the hotels possible
        await this.$store.dispatch('FETCH_LIST_HOTELS', this.query);
      }
      if (this.dealItinerary === null) {
        await this.$store.dispatch('FETCH_DEAL_ITINERARY');
      }
      // document.querySelector('.st-content').scrollTo(0, this.usableHeight);
    },
    async fillThumbImageToHotel() {
      const from = this.completedFilteredHotelOnly.length,
        last = this.filteredHotelOnly.length,
        to = from + this.showHotelCount < last ? from + this.showHotelCount : last;

      // fetch the detail hotel data
      const hotelCodeList = [];
      const indexHotels = {};
      if (last > 0) {
        for (let i = from; i < to; i += 1) {
          const hotel = this.filteredHotelOnly[i];
          hotelCodeList.push(hotel.code);
          indexHotels[hotel.code] = i;
        }
      }

      if (from < last) {
        const listString = hotelCodeList.join(',');
        const detailHotels = await this.$store.dispatch(
          'FETCH_HOTEL_DETAIL_DATA',
          listString,
        );
        detailHotels.forEach((detailHotel) => {
          const hotel = this.filteredHotelOnly[indexHotels[detailHotel.code]];
          if (hotel && detailHotel.images) {
            const imgs = detailHotel.images.find(
              (item) => item.imageTypeCode === 'GEN',
            );
            if (hotel) {
              hotel.hotelImage = `http://photos.hotelbeds.com/giata/${imgs.path}`;
            }
          }
        });
        for (let i = from; i < to; i += 1) {
          this.completedFilteredHotelOnly.push(this.filteredHotelOnly[i]);
        }
      }
    },
    async getHotelsFlights() {
      this.categoryName = this.category.dealTypeCode;
      this.hotels = [];
      // this.flightOnlyItems = [];
      this.filteredHotel = [];
      this.filteredHotelOnly = [];
      let priceList = [];
      this.destinationString = '';
      this.packageFlights = [];
      this.searchResultCount = 0;

      const hotels = [];
      const packageFlights = [];
      if (this.packages && this.packages.length === 0) {
        this.$bvModal.msgBoxOk(this.$t('search-result.no-hotels-message'),
          {
            title: this.$t('product-page.expire-title'),
            dialogClass: 'noSearchResult',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          });
      }
      if (this.packages) {
        if (this.categoryName !== 'Hotel_Only' && this.packages.length > 0) {
          this.currencySymbol = this.packages[0].cc;
          const destList = decodeURIComponent(this.query.dest).split(',');
          this.destinationString = destList.map((dest) => this.category?.destinations?.find((item) => item.code === dest)?.name[this.lang] || dest).join(',') ?? '';

          let prevTranslation = null;
          this.packages.forEach((item) => {
            const hotelImages = this.categoryName === 'Organize_tour_packages' ? item.hotelImages : null;
            let translation = this.categoryName === 'Organize_tour_packages' ? { ...item.translations } : { ...this.packages[0].translations };
            if (Object.keys(translation).length === 0) {
              translation = prevTranslation;
            } else {
              prevTranslation = translation;
            }

            // take hotels with discounted flight
            item.hotels.forEach((hotelItem) => {
              if (hotelItem.discountedFlights && hotelItem.discountedFlights.length > 0) {
                const flights = hotelItem.discountedFlights.map((disItem) => item.flights.find((flightItem) => {
                  if (disItem.flightsId === `${flightItem.fl_id1}${flightItem.fl_id2}`) {
                    flightItem.tp = disItem.discountedPrice.price_average;
                    return true;
                  }
                  return false;
                }));

                const groupFlights = flights.reduce((r, o) => {
                  const k = String(o.tp);
                  // eslint-disable-next-line no-cond-assign
                  if (r[k] || (r[k] = [])) r[k].push(o);
                  return r;
                }, {});

                // eslint-disable-next-line no-restricted-syntax
                for (const key of Object.keys(groupFlights)) {
                  hotels.push({
                    ...hotelItem,
                    flights: groupFlights[key],
                    flightDiscounted: true,
                    hotel_shift: item.hotel_shift_1,
                    translations: {
                      hotelName: translation.hotelName[hotelItem.hotelId],
                      desc: translation.desc[hotelItem.hotelId],
                      basisName: translation.basisName[hotelItem.basisName],
                      Room_Class: translation.Room_Class[hotelItem.Room_Class],
                      Room_Type: translation.Room_Type[hotelItem.Room_Type],
                      flightDestinationName: translation.flightDestinationName,
                      flightDestinationCountry: translation.flightDestinationCountry,
                      fac: translation.fac,
                    },
                    cc: item.cc,
                    destCode: item.destination_1,
                    Itinerary: item.Itinerary,
                    subject: item.subj_id,
                    bClient: item.BClient,
                    hotelImages: hotelImages ? hotelImages[hotelItem.hotelId] : null,
                    supplement: item.addSupplement,
                  });
                }
              }
            });

            // take hotels without discounted flight
            item.hotels.forEach(async (hotelItem) => {
              if (hotelItem.discountedFlights && hotelItem.discountedFlights.length > 0) {
                const flights = await item.flights.filter((flightItem) => undefined === hotelItem.discountedFlights.find((disItem) => disItem.flightsId === `${flightItem.fl_id1}${flightItem.fl_id2}`));

                if (flights.length > 0) {
                  hotels.push({ ...hotelItem,
                    flights,
                    flightDiscounted: false,
                    hotel_shift: item.hotel_shift_1,
                    translations: {
                      hotelName: translation.hotelName[hotelItem.hotelId],
                      desc: translation.desc[hotelItem.hotelId],
                      basisName: translation.basisName[hotelItem.basisName],
                      Room_Class: translation.Room_Class[hotelItem.Room_Class],
                      Room_Type: translation.Room_Type[hotelItem.Room_Type],
                      flightDestinationName: translation.flightDestinationName,
                      flightDestinationCountry: translation.flightDestinationCountry,
                      fac: translation.fac,
                    },
                    cc: item.cc,
                    destCode: item.destination_1,
                    Itinerary: item.Itinerary,
                    subject: item.subj_id,
                    bClient: item.BClient,
                    hotelImages: hotelImages ? hotelImages[hotelItem.hotelId] : null,
                    supplement: item.addSupplement,
                  });
                }
              } else {
                const { flights } = item;
                hotels.push({
                  ...hotelItem,
                  flights,
                  flightDiscounted: false,
                  hotel_shift: item.hotel_shift_1,
                  translations: {
                    hotelName: translation.hotelName[hotelItem.hotelId] ?? '',
                    desc: translation.desc[hotelItem.hotelId],
                    basisName: translation.basisName[hotelItem.basisName],
                    Room_Class: translation.Room_Class[hotelItem.Room_Class],
                    Room_Type: translation.Room_Type[hotelItem.Room_Type],
                    flightDestinationName: translation.flightDestinationName,
                    flightDestinationCountry: translation.flightDestinationCountry,
                    fac: translation?.fac,
                  },
                  cc: item.cc,
                  destCode: item.destination_1,
                  Itinerary: item.Itinerary,
                  subject: item.subj_id,
                  bClient: item.BClient,
                  hotelImages: hotelImages ? hotelImages[hotelItem.hotelId] : null,
                  supplement: item.addSupplement,
                });
              }
            });

            if (this.categoryName === 'Flight_Only' && item.hotels.length === 0) {
              const discountedFO = !!item.discountPercentFO;

              const { translations } = this.packages[0];

              const flightsFO = item.flights.map((d) => {
                let originalPrice = discountedFO ? item.originalPricesFO.priceByAge.find((dd) => dd.age_from === 0 && dd.age_to === 0).price : null;
                let matchingDiscountRuleFO = discountedFO ? item.matchingDiscountRuleFO : null;
                let prices = this.getPriceInfo(item.flightOnlyPrice);

                if (item.discountedFlightsFO) {
                  const discountedFlightFOItem = item.discountedFlightsFO.find((elem) => elem.flightsId === `${d.fl_id1}${d.fl_id2}`);
                  if (discountedFlightFOItem) {
                    prices = this.getPriceInfo(discountedFlightFOItem.discountedPrice?.priceByAge) || null;
                    originalPrice = discountedFlightFOItem.originalPrice?.priceByAge?.find((dd) => dd.age_from === 0 && dd.age_to === 0).price || null;
                    matchingDiscountRuleFO = discountedFlightFOItem.matchingDiscountRule || null;
                  }
                }
                priceList.push(prices.totalPrice);
                return { ...d, ...prices, originalPrice, translations, matchingDiscountRuleFO };
              });
              packageFlights.push(...flightsFO);
            }
          });
          if (this.categoryName !== 'Organize_tour_packages') {
            this.hotels = await this.hotelSort(this.removeDuplicate(hotels));
          } else {
            this.hotels = await this.hotelSort(hotels);
          }
          this.packageFlights.push(...this.flightSort(packageFlights));
          this.searchResultCount = this.hotels.length || this.packageFlights.length;
          if (this.categoryName === 'Flight_Only') {
            this.flightOnlyItems = [];
            this.flightOnlyItems = [...this.packageFlights];
            this.flightOnlyItems.forEach((flight) => {
              flight.FlightDetail.forEach((itemFlight) => {
                itemFlight.translations = flight.translations;
              });
            });

            this.flightOnlyItems.forEach((flight) => {
              this.insertingAirlineInfo(flight);
              flight.cc = this.currencySymbol;
            });
          } else {
            this.hotels.forEach((hotel) => {
              hotel.flights.forEach((flight) => {
                this.insertingAirlineInfo(flight);
              });
            });
            this.filteredHotel = [...this.hotels];
            const idx = this.categoryName === 'vacation_pack' ? 0 : 1;
            this.filteredFlightHotel[idx] = this.filteredHotel;
          }
          await this.firstSort();

          if (this.hotels.length === 0 && this.categoryName !== 'Flight_Only') {
            this.$bvModal.msgBoxOk(this.$t('search-result.no-hotels-message'),
              {
                title: this.$t('product-page.expire-title'),
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
              });
          }
          if (this.flightOnlyItems.length === 0 && this.categoryName === 'Flight_Only') {
            this.$bvModal.msgBoxOk(this.$t('search-result.no-flights-message'),
              {
                title: this.$t('product-page.expire-title'),
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
              });
          }
        } else if (this.categoryName === 'Hotel_Only') {
          // add hotels
          if (this.packages.hotels && this.packages.hotels.total > 0) {
            this.filteredHotelOnly = this.packages.hotels.hotels;
            this.filteredHotelOnly.forEach((hotel) => {
              const strGrade = hotel.categoryCode.replace('EST', '');
              hotel.grade = Number(strGrade);
              if (Number.isNaN(hotel.grade)) hotel.grade = 0;
              hotel.checkIn = this.packages.hotels.checkIn;
              hotel.checkOut = this.packages.hotels.checkOut;
              hotel.Price = Math.round(Number(hotel.minRate) + Number(hotel.minRate) * 0.2);
              priceList.push(hotel.Price);
            });
            // pending : create the priceFilterItem
            priceList = [...new Set(priceList)].sort((a, b) => a - b);
            if (this.filteredHotelOnly.length > 1) {
              this.updatePriceRangeFilterData(priceList);
            } else {
              this.priceFilterItem = null;
            }
          }
          // end add hotels
        }
      }

      this.updatePriceRangeFilterData();
    },
    updatePriceRangeFilterData() {
      const kindCate = this.categoryName;
      let priceList = [];
      if ((kindCate === 'vacation_pack' || kindCate === 'Organize_tour_packages') && this.filteredHotel) {
        priceList = this.filteredHotel.length > 0 ? this.getPriceList(this.filteredHotel, 'priceAverage') : [];
      } else if (kindCate === 'Flight_Only' && this.flightOnlyItems) {
        priceList = this.flightOnlyItems.length > 0 ? this.getPriceList(this.flightOnlyItems, 'totalPrice') : [];
      }
      if (priceList.length > 1) {
        const minPrice = Number(priceList[0]);
        const maxPrice = Number(priceList[priceList.length - 1]);

        const priceFilter = {
          kind: 'slider',
          title: 'price',
          value: [minPrice, maxPrice],
          min: minPrice,
          max: maxPrice,
          marks: priceList,
          symbol: this.packages[0].cc,
        };
        this.$set(this.filterOptions, 'priceSlider', priceFilter);
        this.filterPriceValues = [minPrice, maxPrice];

        this.priceFilterItem = priceFilter;
      } else {
        this.$set(this.filterOptions, 'priceSlider', null);
      }
      if (kindCate === 'vacation_pack') {
        this.$set(this.filterOptions, 'star', this.starRateFilterOption);
        this.$set(this.filterOptions, 'hotelBasis', this.hotelBasisFilterOption);
        this.$set(this.filterOptions, 'hotelName', this.hotelNameFilterOption);
        this.$set(this.filterOptions, 'hotelSubject', null);
        this.$set(this.filterOptions, 'hotelCity', null);
        this.$set(this.filterOptions, 'travelDuration', null);
      } else if (kindCate === 'Organize_tour_packages') {
        this.$set(this.filterOptions, 'hotelBasis', this.hotelBasisFilterOption);
        this.$set(this.filterOptions, 'hotelSubject', this.hotelSubjectFilterOption);
        this.$set(this.filterOptions, 'hotelCity', this.hotelCityFilterOption);
        this.$set(this.filterOptions, 'travelDuration', this.travelDaysFilterOption);
        this.$set(this.filterOptions, 'hotelName', null);
      } else {
        this.filterOptions = {};
      }
    },
    placeHighlightedHotelBestTop() {
      const sortArray = [], highlightHotels = [];
      this.filteredHotel.forEach((hotelItem) => {
        if (hotelItem.matchingDiscountRule?.isHighlighted) {
          highlightHotels.push(hotelItem);
        } else {
          sortArray.push(hotelItem);
        }
      });
      this.filteredHotel = highlightHotels.concat(sortArray);
    },
    firstSort() {
      const sortAgencyCode = this.marketerOdyAgencyCode || this.regularOdyAgencyCode;

      if (this.isOdysseySite) {
        this.filteredHotel.sort((a, b) => {
          if (a.cheapestForAgents.priceBClient === b.cheapestForAgents.priceBClient) {
            return a.priceAverage - b.priceAverage;
          } else {
            return (a.cheapestForAgents.priceBClient && !b.cheapestForAgents.priceBClient) ? -1 : 1;
          }
        });
      } else {
        this.filteredHotel.sort((a, b) => {
          if (a.bClient === b.bClient) {
            return a.priceAverage - b.priceAverage;
          } else {
            return (a.bClient === sortAgencyCode && b.bClient !== sortAgencyCode) ? -1 : 1;
          }
        });
        this.placeHighlightedHotelBestTop();
      }
    },
    doSort(arr) {
      this.completedFilteredHotelOnly = [];
      this.lastSort = [...arr];
      this.applySort(arr[0], arr[1]);
    },
    applySort(type, asc) {
      let sortArray = [];
      switch (this.categoryName) {
        case 'Hotel_Only':
          sortArray = this.filteredHotelOnly || [];
          break;
        default:
          sortArray = this.filteredHotel || [];
      }
      if (type === 'grade') {
        if (asc) {
          sortArray.sort((a, b) => a.grade - b.grade);
        } else {
          sortArray.sort((a, b) => b.grade - a.grade);
        }
      }
      if (type === 'price') {
        if (asc) {
          sortArray.sort((a, b) => a.priceAverage - b.priceAverage);
        } else {
          sortArray.sort((a, b) => b.priceAverage - a.priceAverage);
        }
      }
      if (type === 'days') {
        // if (asc) {
        //   sortArray.sort((a, b) => Number(a.duration) - Number(b.duration));
        // } else {
        //   sortArray.sort((a, b) => Number(b.duration) - Number(a.duration));
        // }
        if (asc) {
          sortArray.sort((a, b) => this.getCheckInDate(a) - this.getCheckInDate(b));
        } else {
          sortArray.sort((a, b) => this.getCheckInDate(b) - this.getCheckInDate(a));
        }
      }
      if (type === 'hotelName') {
        let result = [];
        if (asc) {
          result = this.hotels.filter((item) => asc.toLowerCase().split(' ').every((v) => item.hotelName.toLowerCase().includes(v)));
        } else {
          result = this.hotels;
        }
        this.filteredHotel = [...result];
        this.searchResultCount = this.filteredHotel.length;
      }
      this.placeHighlightedHotelBestTop();
    },
    getCheckInDate(hotel) {
      const { flights } = hotel;
      const checkIn = dayjs(flights[0]?.FlightDetail[0]?.FL_Date).add(Number(hotel?.hotel_shift), 'day');
      return new Date(dayjs(checkIn).format('YYYY-MM-DD'));
    },
    filterPrice(value) { // remove this function after mobile is finished too
      this.filterPriceValues = value;
    },
    async applyPriceRangeFilter() {
      const _this = this;
      if (this.categoryName === 'Flight_Only') {
        const result = await this.packageFlights.filter(
          (item) => Number(item.totalPrice) >= this.filterPriceValues[0]
            && Number(item.totalPrice) <= this.filterPriceValues[1],
        );
        this.flightOnlyItems = [...result];
        this.flightOnlyItems.forEach((flight) => {
          _this.insertingAirlineInfo(flight);
        });
      } else if (this.categoryName === 'Hotel_Only') {
        const result = await this.packages.hotels.hotels.filter(
          (item) => Number(item.Price) >= this.filterPriceValues[0]
            && Number(item.Price) <= this.filterPriceValues[1],
        );
        this.filteredHotelOnly = [...result];
      } else {
        const result = await this.hotels.filter(
          (item) => Number(item.priceAverage) >= this.filterPriceValues[0]
            && Number(item.priceAverage) <= this.filterPriceValues[1],
        );
        this.filteredHotel = [...result];
      }
    },
    insertingAirlineInfo(flight) {
      const pack = this.categoryName === 'Organize_tour_packages' ? this.packages.find((d) => d.packId === flight.PCK_ID) : this.packages[0];
      // flight.airlineInfo = null;
      if (pack.airlineInfo) {
        let barCode = flight.FlightDetail[0].FL_AIRLINE;
        if (pack.airlineInfo && pack.airlineInfo[barCode]) flight.FlightDetail[0].airlineInfo = pack.airlineInfo[barCode];

        barCode = flight.FlightDetail[1].FL_AIRLINE;
        if (pack.airlineInfo && pack.airlineInfo[barCode]) flight.FlightDetail[1].airlineInfo = pack.airlineInfo[barCode];
      }
    },
    getPriceInfo(pPricesAge) {
      if (!pPricesAge) return null;
      const minPrice = pPricesAge.find(
        (d) => d.age_from === 0 && d.age_to === 0,
      ).price;

      let totalPrice = 0;
      if (Number(this.query.adult) > 0) {
        totalPrice
          += Number(this.query.adult)
          * pPricesAge.find((d) => d.age_from === 0 && d.age_to === 0).price;
      }
      if (Number(this.query.child > 0)) {
        totalPrice
          += Number(this.query.child)
          * pPricesAge.find((d) => d.age_from === 2 && d.age_to === 12).price;
      }
      if (Number(this.query.infant) > 0) {
        totalPrice
          += Number(this.query.infant)
          * pPricesAge.find((d) => d.age_from === 0 && d.age_to === 2).price;
      }
      return { minPrice, totalPrice };
    },
    quickSearch(pItem) {
      this.quickSearches = [];
      const body = {
        dest: pItem.dest,
        fromDate: pItem.packStartDate,
        toDate: pItem.packEndDate,
        categoryId: pItem.categoryId,
        adult: pItem.adult,
        child: pItem.child,
        infant: pItem.infant,
      };
      this.$router.push({ path: '/search-result', query: body });
    },
    updatedDestination(pDestinationString) {
      this.destinationString = pDestinationString;
    },
    sortArray([...arr], sortItem, order = 1) {
      arr.sort((a, b) => {
        if (Number(a[sortItem]) > Number(b[sortItem])) {
          return order;
        } else if (Number(a[sortItem]) < Number(b[sortItem])) {
          return -1 * order;
        }
        return 0;
      });
      return arr;
    },
    flightSort(arr) {
      const { isOdysseySite, cheapestForAgents } = this;
      let dArr = [...arr];
      if (isOdysseySite && cheapestForAgents) {
        dArr = arr.filter((d) => cheapestForAgents[`${d.fl_id1}${d.fl_id2}`].packageId === d.PCK_ID).map((d) => ({ ...d, cheapestForAgents: cheapestForAgents[`${d.fl_id1}${d.fl_id2}`] }));
      }
      const showHotIconFlights = dArr.filter((item) => item.matchingDiscountRuleFO && (item.matchingDiscountRuleFO?.roundtripFlightsCode !== null || item.matchingDiscountRuleFO?.ruleType === 2));
      const otherFlights = dArr.filter((item) => !item.matchingDiscountRuleFO || (item.matchingDiscountRuleFO?.roundtripFlightsCode === null && item.matchingDiscountRuleFO?.ruleType !== 2));
      return [
        ...this.sortArray(showHotIconFlights, 'minPrice'),
        ...this.sortArray(otherFlights, 'minPrice'),
      ];
    },
    hotelSort(arr) {
      const showHotIconHotels = arr.filter((item) => this.isShowHotelHotIcon(item));
      const otherHotels = arr.filter((item) => !this.isShowHotelHotIcon(item));
      return [
        ...this.sortArray(showHotIconHotels, 'priceAverage'),
        ...this.sortArray(otherHotels, 'priceAverage'),
      ];
    },
    isShowHotelHotIcon(hotel) {
      return (hotel.discountPercent && hotel.matchingDiscountRule?.hotelId !== null) || this.isShowFlightHotIcon(hotel);
    },
    isShowFlightHotIcon(hotel) {
      return hotel.flightDiscounted && this.getDiscountFlightInfo(hotel)?.matchingDiscountRule?.hotelId !== null;
    },
    getDiscountFlightInfo(hotel) {
      const flight = hotel.flights[0];
      return hotel.discountedFlights.find((item) => (item.flightsId === `${flight.fl_id1}${flight.fl_id2}`));
    },
    removeDuplicate([...arr]) {
      const resultArr = [];
      const { isOdysseySite, cheapestForAgents } = this;
      if (isOdysseySite && cheapestForAgents) {
        arr.forEach((item) => {
          if (cheapestForAgents[item.La_ID] && cheapestForAgents[item.La_ID].packageId === item.PCK_ID) {
            item.cheapestForAgents = cheapestForAgents[item.La_ID];
            resultArr.push(item);
          }
        });
      } else {
        arr.forEach((item) => {
          const index = resultArr.findIndex((elem) => item.La_ID === elem.La_ID);
          if (index > -1) {
            if (cheapestForAgents[item.La_ID] && cheapestForAgents[item.La_ID].packageId === item.PCK_ID) {
              item.cheapestForAgents = cheapestForAgents[item.La_ID];
              resultArr[index] = item;
            } else if (item.priceAverage < resultArr[index].priceAverage) {
              resultArr[index] = item;
            }
          } else {
            resultArr.push(item);
          }
        });
      }

      return resultArr;
    },
    getPriceList([...arr], props) {
      const list = arr.map((item) => item[props]);
      return [...new Set(list)].sort((a, b) => a - b);
    },
    async filterByOption(conditions) {
      let hotels = this.packages?.hotels?.hotels || this.hotels;
      let result = [];

      if (conditions.price) {
        this.filterPriceValues = conditions.price;
        await this.applyPriceRangeFilter();
        if (this.categoryName === 'Hotel_Only') {
          hotels = [...this.filteredHotelOnly];
        } else {
          hotels = [...this.filteredHotel];
        }
      }

      if (this.categoryName === 'vacation_pack') {
        result = hotels.filter((item) => {
          const cond1 = (conditions['hotel-name'] && conditions['hotel-name'].length) ? conditions['hotel-name'].indexOf(item.hotelName) > -1 : true;
          const cond2 = (conditions['star-rate'] && conditions['star-rate'].length) ? conditions['star-rate'].indexOf(Number(item.grade)) > -1 : true;
          const cond3 = (conditions['hotel-basis'] && conditions['hotel-basis'].length) ? conditions['hotel-basis'].indexOf(item.basisName) > -1 : true;
          return cond1 && cond2 && cond3;
        });
        this.filteredHotel = [...result];
      }

      if (this.categoryName === 'Organize_tour_packages') {
        let subSubjects = [];
        if (conditions['hotel-subject'] && conditions['hotel-subject'].length) {
          subSubjects = conditions['hotel-subject'].reduce((total, item) => total.concat(...this.subjectCategory.find((d) => d.id === item)?.subjectCodes), []);
        }
        result = hotels.filter((item) => {
          const cond1 = (subSubjects.length) ? subSubjects.indexOf(item.subject) > -1 : true;
          const cond2 = (conditions['hotel-city'] && conditions['hotel-city'].length) ? conditions['hotel-city'].indexOf(item.destCode) > -1 : true;
          const cond3 = (conditions['travel-duration'] && conditions['travel-duration'].length) ? conditions['travel-duration'].indexOf(item.duration) > -1 : true;
          const cond4 = (conditions['hotel-basis'] && conditions['hotel-basis'].length) ? conditions['hotel-basis'].indexOf(item.basisName) > -1 : true;
          return cond1 && cond2 && cond3 && cond4;
        });
        this.filteredHotel = [...result];
      }

      if (this.categoryName === 'Hotel_Only') {
        this.filteredHotelOnly = [...result];
      }
      this.searchResultCount = this.filteredHotel.length;

      if (this.lastSort.length === 0) {
        this.firstSort();
      } else {
        this.applySort(this.lastSort[0], this.lastSort[1]);
      }
      document.querySelector('.st-content').scrollTo(0, this.usableHeight);
    },
    onScroll() {
      this.isScroll = false; // Number(event.target.scrollTop) > 260;
    },
    openGalleryShareModal(payload) {
      if (payload.type === 'gallery') {
        this.crtGalleryInx = payload.index;
        this.gallerySliderModal = true;
        this.galleryModalBookLink = payload.link;
        this.shareHotel = payload.hotel;
      } else {
        this.shareIndex = payload.index;
        this.shareThisModal = true;
        this.shareLink = payload.link;
        this.shareHotel = payload.hotel;
        this.shareImage = payload.image;
        this.shareRate = payload.rate;
      }
    },
  },
};
</script>

<style scoped>
.desktop_form.sticky{
  position: sticky;
  top: 53px;
  z-index: 10;
  color: #004085;
  background-color: #cce5ff;
  display: flex;
  justify-content: space-around;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem
}
.desktop_form.sticky h3, .desktop_form.sticky h4{
  margin-bottom: 0;
  align-self: center;
}
.desktop_form.sticky h3{
  font-size: 1.2rem;
}
.desktop_form.sticky h4{
  font-size: 1rem;
}
@media (max-width: 768px){
  .desktop_form.sticky h3 {
    margin-top: 0;
  }
}
@media (max-width: 479px) {
  .sportspage_area {
    margin: 0 !important;
  }
}
</style>

<style>
#gallery-modal .modal-body {
  margin: 0;
  padding: 0;
}
.list-inline {
  padding-right: 0;
}

.filter-hotel
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  right: -0.5rem;
}

.dVacation-pack.filter-cont .filterItem:not(:first-child) {
  margin-top: 0px;
}

/* .modal.show .modal-dialog.noSearchResult {
  display: inline-block;
  top: calc(50vh - 100px);
} */

.modal-dialog.noSearchResult .modal-body{
  text-align: center;
}

@media (max-width: 479px) {
  .modal.show .modal-dialog.noSearchResult {
    display: flex;
    left: -10px;
    top: unset;
  }
}
</style>
