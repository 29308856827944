<template>
  <div style="margin-top: 10rem;">
    <router-view />
    <Footer/>
  </div>
</template>

<script>

export default {
  name: 'changedPricePlan',
  components: {
    Footer: () => import('@/components/HeaderFooter/Footer'),
  },
};
</script>
<style>

</style>
